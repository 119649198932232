@font-face {
    font-display: swap;
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(/assets/fm-icons/MaterialIcons-Regular.eot);
    /* For IE6-8 */
    src: local('Material Icons'),
        local('MaterialIcons-Regular'),
        url(/assets/fm-icons/MaterialIcons-Regular.woff2) format('woff2'),
        url(/assets/fm-icons/MaterialIcons-Regular.woff) format('woff'),
        url(/assets/fm-icons/MaterialIcons-Regular.ttf) format('truetype');
}


/** =================== MONTSERRAT =================== **/

/** Montserrat Regular **/
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    src: url('/assets/webfonts/Montserrat-Regular.eot');
    src: url('/assets/webfonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/Montserrat-Regular.woff2') format('woff2'),
        url('/assets/webfonts/Montserrat-Regular.woff') format('woff');
}

/** Montserrat Regular-Italic **/
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: italic;
    src: url('/assets/webfonts/Montserrat-Italic.eot');
    src: url('/assets/webfonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/Montserrat-Italic.woff2') format('woff2'),
        url('/assets/webfonts/Montserrat-Italic.woff') format('woff');
}

/** Montserrat Medium **/
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;
    src: url('/assets/webfonts/Montserrat-Medium.eot');
    src: url('/assets/webfonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/Montserrat-Medium.woff2') format('woff2'),
        url('/assets/webfonts/Montserrat-Medium.woff') format('woff');
}

/** Montserrat Medium-Italic **/
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: italic;
    src: url('/assets/webfonts/Montserrat-MediumItalic.eot');
    src: url('/assets/webfonts/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/Montserrat-MediumItalic.woff2') format('woff2'),
        url('/assets/webfonts/Montserrat-MediumItalic.woff') format('woff');
}

/** Montserrat SemiBold **/
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: normal;
    src: url('/assets/webfonts/Montserrat-SemiBold.eot');
    src: url('/assets/webfonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('/assets/webfonts/Montserrat-SemiBold.woff') format('woff');
}

/** Montserrat SemiBold-Italic **/
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: italic;
    src: url('/assets/webfonts/Montserrat-SemiBoldItalic.eot');
    src: url('/assets/webfonts/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('/assets/webfonts/Montserrat-SemiBoldItalic.woff') format('woff');
}

/** Montserrat Bold **/
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;
    src: url('/assets/webfonts/Montserrat-Bold.eot');
    src: url('/assets/webfonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/Montserrat-Bold.woff2') format('woff2'),
        url('/assets/webfonts/Montserrat-Bold.woff') format('woff');
}

/** Montserrat Bold-Italic **/
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: italic;
    src: url('/assets/webfonts/Montserrat-BoldItalic.eot');
    src: url('/assets/webfonts/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/Montserrat-BoldItalic.woff2') format('woff2'),
        url('/assets/webfonts/Montserrat-BoldItalic.woff') format('woff');
}

/** Montserrat ExtraBold **/
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-weight: 800;
    font-style: normal;
    src: url('/assets/webfonts/Montserrat-ExtraBold.eot');
    src: url('/assets/webfonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('/assets/webfonts/Montserrat-ExtraBold.woff') format('woff');
}

/** Montserrat ExtraBold-Italic **/
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-weight: 800;
    font-style: italic;
    src: url('/assets/webfonts/Montserrat-ExtraBoldItalic.eot');
    src: url('/assets/webfonts/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('/assets/webfonts/Montserrat-ExtraBoldItalic.woff') format('woff');
}
