@font-face {
  font-family: 'fontello';
  src: url('~/assets/fm-icons/fontello.eot?583380');
  src: url('~/assets/fm-icons/fontello.eot?583380#iefix') format('embedded-opentype'),
       url('~/assets/fm-icons/fontello.woff2?583380') format('woff2'),
       url('~/assets/fm-icons/fontello.woff?583380') format('woff'),
       url('~/assets/fm-icons/fontello.ttf?583380') format('truetype'),
       url('~/assets/fm-icons/fontello.svg?583380#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('~/assets/fm-icons/fontello.svg?46206205#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello", sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-picture:before { content: '\e800'; } /* '' */
.icon-picture:before { content: '\e801'; } /* '' */
.icon-fm-print:before { content: '\e802'; } /* '' */
.icon-fm-referral:before { content: '\e803'; } /* '' */
.icon-fm-reffered-from:before { content: '\e804'; } /* '' */
.icon-fm-reffer-to:before { content: '\e805'; } /* '' */
.icon-fm-report:before { content: '\e806'; } /* '' */
.icon-fm-save:before { content: '\e807'; } /* '' */
.icon-fm-search:before { content: '\e808'; } /* '' */
.icon-fm-sent:before { content: '\e809'; } /* '' */
.icon-fm-settings:before { content: '\e80a'; } /* '' */
.icon-fm-sort:before { content: '\e80b'; } /* '' */
.icon-fm-test-results:before { content: '\e80c'; } /* '' */
.icon-fm-starred:before { content: '\e80d'; } /* '' */
.icon-fm-trash:before { content: '\e80e'; } /* '' */
.icon-fm-starred-chosen:before { content: '\e80f'; } /* '' */
.icon-fm-unlock:before { content: '\e810'; } /* '' */
.icon-fm-3dotssubmenu:before { content: '\e811'; } /* '' */
.icon-fm-vitals:before { content: '\e812'; } /* '' */
.icon-fm-visible:before { content: '\e813'; } /* '' */
.icon-fm-addressbook:before { content: '\e814'; } /* '' */
.icon-fm-allergies:before { content: '\e815'; } /* '' */
.icon-fm-archive:before { content: '\e816'; } /* '' */
.icon-fm-attachments-alerts:before { content: '\e817'; } /* '' */
.icon-fm-body-measurement:before { content: '\e818'; } /* '' */
.icon-fm-billing:before { content: '\e819'; } /* '' */
.icon-fm-calendar:before { content: '\e81a'; } /* '' */
.icon-fm-check:before { content: '\e81b'; } /* '' */
.icon-freddiemed-icon_video-call:before { content: '\e81c'; } /* '' */
.icon-fm-care-group:before { content: '\e81d'; } /* '' */
.icon-fm-contact-card:before { content: '\e81e'; } /* '' */
.icon-fm-currentappointment:before { content: '\e81f'; } /* '' */
.icon-fm-diagnosis-management:before { content: '\e820'; } /* '' */
.icon-fm-diagnostics-request:before { content: '\e821'; } /* '' */
.icon-fm-docs-temps:before { content: '\e822'; } /* '' */
.icon-fm-draft:before { content: '\e823'; } /* '' */
.icon-fm-dropdown:before { content: '\e824'; } /* '' */
.icon-fm-editoslot:before { content: '\e825'; } /* '' */
.icon-fm-exit:before { content: '\e826'; } /* '' */
.icon-fm-female:before { content: '\e827'; } /* '' */
.icon-fm-goback:before { content: '\e828'; } /* '' */
.icon-fm-group:before { content: '\e829'; } /* '' */
.icon-worklist:before { content: '\e82a'; } /* '' */
.icon-icon-worklist-new:before { content: '\e82b'; } /* '' */
.icon-icon-go-to-calendar:before { content: '\e82c'; } /* '' */
.icon-fm-icon-cancelled:before { content: '\e82d'; } /* '' */
.icon-fm-icon-checkedin:before { content: '\e82e'; } /* '' */
.icon-icon-preassessment:before { content: '\e82f'; } /* '' */
.icon-upgrade:before { content: '\e830'; } /* '' */
.icon-two-step:before { content: '\e831'; } /* '' */
.icon-freddiemed-icon_video-call-exit-fulscreen:before { content: '\e832'; } /* '' */
.icon-freddiemed-icon_video-call-fulscreen:before { content: '\e833'; } /* '' */
.icon-freddiemed-icon_video-call-minimize-window:before { content: '\e834'; } /* '' */
.icon-freddiemed-icon_video-call-mute:before { content: '\e835'; } /* '' */
.icon-freddiemed-icon_video-call-regularscreen:before { content: '\e836'; } /* '' */
.icon-freddiemed-icon_video-call-thumbnail:before { content: '\e837'; } /* '' */
.icon-freddiemed-icon_video-call-unmute:before { content: '\e838'; } /* '' */
.icon-freddiemed-icon_video-disabled:before { content: '\e839'; } /* '' */
.icon-fm-checkbox:before { content: '\e83a'; } /* '' */
.icon-fm-related-invoice:before { content: '\e83b'; } /* '' */
.icon-icon-uploaddoc:before { content: '\e83c'; } /* '' */
.icon-fm-pencil:before { content: '\e83d'; } /* '' */
.icon-fm-pause:before { content: '\e83e'; } /* '' */
.icon-pending-approval:before { content: '\e83f'; } /* '' */
.icon-filter_icon:before { content: '\e840'; } /* '' */
.icon-fm-hamburger-menu:before { content: '\e858'; } /* '' */
.icon-fm-heart:before { content: '\e859'; } /* '' */
.icon-fm-immunisations:before { content: '\e85a'; } /* '' */
.icon-fm-important:before { content: '\e85b'; } /* '' */
.icon-fm-inbox:before { content: '\e85c'; } /* '' */
.icon-fm-invisible:before { content: '\e85d'; } /* '' */
.icon-fm-invoice:before { content: '\e85e'; } /* '' */
.icon-fm-label:before { content: '\e85f'; } /* '' */
.icon-fm-link:before { content: '\e860'; } /* '' */
.icon-fm-logout:before { content: '\e861'; } /* '' */
.icon-fm-lock:before { content: '\e862'; } /* '' */
.icon-fm-mais:before { content: '\e863'; } /* '' */
.icon-fm-message-balao:before { content: '\e864'; } /* '' */
.icon-fm-microphone:before { content: '\e865'; } /* '' */
.icon-fm-messages:before { content: '\e866'; } /* '' */
.icon-fm-navigation-collapse:before { content: '\e867'; } /* '' */
.icon-fm-navigation-attachment:before { content: '\e868'; } /* '' */
.icon-fm-navigation-download:before { content: '\e869'; } /* '' */
.icon-fm-navigation-left:before { content: '\e86a'; } /* '' */
.icon-fm-navigation-dropdown:before { content: '\e86b'; } /* '' */
.icon-fm-navigation-right:before { content: '\e86c'; } /* '' */
.icon-fm-notifications:before { content: '\e86d'; } /* '' */
.icon-fm-patientfiles:before { content: '\e86e'; } /* '' */
.icon-fm-patient-health-data:before { content: '\e86f'; } /* '' */
.icon-fm-prescription-history:before { content: '\e870'; } /* '' */
.icon-multidep:before { content: '\e871'; } /* '' */
.icon-fm-icon-booking-calendar:before { content: '\e872'; } /* '' */
.icon-fm-vitals_1:before { content: '\e873'; } /* '' */
.icon-fm-testresults:before { content: '\e874'; } /* '' */
.icon-fm-icon-drag-drop:before { content: '\e875'; } /* '' */
.icon-fm-icon-info:before { content: '\e876'; } /* '' */
.icon-zendesk:before { content: '\e877'; } /* '' */
.icon-fm-icon-noshow:before { content: '\e879'; } /* '' */
.icon-fm-icon-pin:before { content: '\e87a'; } /* '' */
.icon-fm-icon-immunisations:before { content: '\e87b'; } /* '' */
.icon-fm-icon-screenings:before { content: '\e87c'; } /* '' */
.icon-fm-icon-allergiesnew:before { content: '\e87d'; } /* '' */
.icon-fm-icon-duplicate:before { content: '\e87e'; } /* '' */
.icon-fm-icon-demographics:before { content: '\e87f'; } /* '' */
.icon-fm-icon-summary:before { content: '\e880'; } /* '' */
.icon-fm-icon-dashboard:before { content: '\e881'; } /* '' */
.icon-patient-files-v2:before { content: '\e882'; } /* '' */
.icon-addendum-2:before { content: '\e883'; } /* '' */
.icon-addendum-3:before { content: '\e884'; } /* '' */
.icon-alert-immunisations:before { content: '\e885'; } /* '' */
.icon-user-profile:before { content: '\e886'; } /* '' */
.icon-examination-icon:before { content: '\e887'; } /* '' */
.icon-check-in-empty-state:before { content: '\e888'; } /* '' */
.icon-icon-attachments-examination:before { content: '\e889'; } /* '' */
.icon-fm-icon-tick:before { content: '\e88a'; } /* '' */
.icon-fm-icon-pencil:before { content: '\e88b'; } /* '' */
.icon-archive:before { content: '\e8b4'; } /* '' */
.icon-star-filled:before { content: '\e8b5'; } /* '' */
.icon-unarchive:before { content: '\e8b6'; } /* '' */
