/* DIALOG */

// @each $element in $safari-dialog-fix {
//   _::-webkit-full-page-media, _:future, :root .mat-dialog-container > #{$element} * {
//     flex-basis: auto !important;
//   }
// }

.fm-component,
.fm-popup {
    // position: relative;

    /** Main button */
    #fm-main-action {
        position: fixed;
        bottom: 32px;
        right: 32px;
    }
}

.mat-dialog-container {
    position: relative;
    height: auto !important;

    &:before {
        top: 0;
        right: 0;
    }
}

.fm-dialog-backdrop-blue {
    background-color: $backdrop;
}

.dialog-profile {
    mat-dialog-container {
        padding: 0;
        background-color: $near-white;
        overflow-x: hidden;

        .fm-content {
            padding: 30px;
        }
    }
}

.dialog-booking {
    mat-dialog-container {
        padding: 0;
        background-color: $white;
        overflow-x: hidden;

        .fm-content {
            padding: 30px;
        }
    }
}

.blue-background {
    .mat-dialog-container {
        background: $primary;
    }
}

.no-padding {
    .mat-dialog-container {
        padding: 0;
    }
}

.freddie-dialog {
    mat-dialog-container {
        padding: 0 0 20px 0;
        background-color: $white;

        .mat-dialog-close-btn {
            cursor: pointer;
            position: absolute;
            top: 12px;
            right: 12px;
            width: 40px;
            height: 40px;
            background: none;
            border: 0px;
            color: $white;
            z-index: 1;

            &:hover {
                color: $near-white;
            }
        }

        .mat-dialog-title {
            background-color: $primary;
            color: $white;
            padding: 18px 60px 18px 35px;
            font-family: $font-montserrat;
            font-size: 20px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
        }

        mat-dialog-content,
        mat-dialog-actions {
            padding: 0 36px;
            margin: 0;
        }
    }
}

.switch-user-dialog {
    mat-dialog-container {
        border-radius: 4px;

        #close-btn {
            color: $primary;
            top: 20px;

            mat-icon {
                font-size: 25px;
            }
        }
    }
}

// Patient files
.dialog_patient_files {
    ::ng-deep html.cdk-global-scrollblock {
        overflow: hidden !important;
    }

    .inner-box {
        padding: 18px 0;
        box-sizing: border-box;
    }

    mat-card {
        z-index: 2;
    }

    .form {
        // border-top: 15px solid $btn-green;

        @each $key, $color in $patient-colors {
            &.#{$key} {
                border-top: 15px solid $color;
            }
        }

        z-index: 2;

        .sub-title {
            font-family: $font-montserrat;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.2px;
            text-align: center;
            color: $table-text-color;
        }
    }

    .buttons {
        z-index: 1;
        margin: 0 20px;

        .save {
            color: $white;
            background: $primary;
        }

        .confirm {
            color: $azure;
        }

        .wrapper {
            margin-top: -17px;

            &.adjust {
                margin-top: 0;
            }

            button {
                padding: 23px 30px;
                box-sizing: border-box;
                font-family: $font-montserrat;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.1px;
                text-align: center;
                color: $table-text-color;
                background-color: $white;

                &:hover {
                    color: $primary;
                }

                &:disabled {
                    @include opacity(0.8);

                    &:hover {
                        color: $table-text-color;
                    }
                }

                &.new {
                    background-color: $btn-new-azure;
                }
            }
        }
    }
}
