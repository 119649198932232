.mat-form-field-should-float .mat-form-field-label {
    color: $primary;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.4px;
}

.mat-form-field {
  line-height: normal;
  font-family: $font-montserrat;
  font-size: 14px;
  color: $input-text-black;

  .mat-form-field-underline {
    bottom: 1.25em;
    height: 2px;
    background-color: $notify-border-color;
    color: $input-text-black;
  }
}

.large-panel {
  .mat-option {
    max-width: 270px;
  }

  &.mat-autocomplete-panel {
    min-width: 270px;
  }
}

.mat-option.fm-user-item {
  min-height: 64px;
  line-height: 100% !important;
}

