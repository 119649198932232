.fm-popup {
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  z-index: 999;
  background: $white;
  overflow: hidden;
  box-shadow: $pop-up-shadow;
}

.fm-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $sombra-raised-btn-01;
  z-index: 998;
}

.fm-centered-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  z-index: 999;
  background: transparent;
}

.fm-centered-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, $primary, $accent);
  z-index: 998;
}
