/* FONTS */
$font-montserrat: 'Montserrat', sans-serif;
$fontello: 'fontello';

/* HEADER */
$header-height: 64px;

/* FOOTER */
$footer-height: 60px;

/* TABLE */
$table-row-hover: #efefef;
$fm-component-menu: #f7f7f7;
$fm-component-content: #ffffff;

/* MAIN COLORS */
$primary: #597bef;
$accent: #13b7e4;
$warn: #ef76e8;
$error: #f44336;
$dark: #141b36;

/* USER STATUS */
$user-online: #9bc516;
$user-away: #fede35;
$user-dnd: #ff3f70;
$user-offline: #d6d6d6;
$user-status-border: white;

$calendar-badge: #b94a48;
$cal-events: #555;
$cal-day: #ededed;
$now-color: #a6aefd;

$progress-bar-buffer: #06d65c;
$progress-bar-fill: #868686;

/* COLORS */
$fm-purple: #6587fb;
$popup-background: #597cef;
$text-color: #597bef;
$text-color-hover: black;
$link-text-color-hover: #597bef;
$btn-border-color: #263238;
$text-header-color: #597bef;
$table-row-border: #f7f7f7;
$table-text-color: #5e5e5e;
$e-mail-sender: #3b3b3b;
$backdrop: rgba($popup-background, 0.24);
$solid-line-color: rgba($table-text-color, 0.72);
$time-color: rgba($table-text-color, 0.6);
$notify-border-color: rgba($table-text-color, 0.2);
$bg-notify: rgba($popup-background, 0.3);
$input-text-black: #263238;
$table-header-color: rgba($table-text-color, 0.72);
$row-selected-color: rgba(121, 226, 255, 0.2);
$background-user: rgba(255, 255, 255, 0.8);
$day-view-detached-block: rgba(151, 151, 151, 0.4);
$btn-new-azure: rgba(19, 183, 208, 0.06);
$flag-shadow: rgba(255, 255, 255, 0.6);
$nth-child-odd: rgba(0, 0, 0, 0.1);
$grey-background: rgba(255, 255, 255, 0.1);
$background-content: rgba(235, 239, 253, 0.6);
$note-book-bg: rgba(255, 233, 122, 0.32);
$telephone-inputs: #999;
$bg-sidebar: rgba($popup-background, 0.4);
$addendum: rgba($popup-background, 0.15);

$list-color: rgba($table-text-color, 0.2);
$past-color: rgba($table-text-color, 0.2);

$selected: rgba(89, 123, 239, 0.08);

/* SHADOWS */
$sombra-raised-btn-01: rgba(59, 59, 59, 0.12);
$sombra-raised-btn-02: rgba(59, 59, 59, 0.24);
$sombra-content-01: rgba(59, 59, 59, 0.12);
$sombra-content-02: rgba(59, 59, 59, 0.24);
$sombra-content-03: 1px 5px 0 rgba(0, 0, 0, 0.12);
$sombra-mail: 0 4px 8px 0 rgba(59, 59, 59, 0.24);
$pop-up-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12);
$nav-bar-notification: #f45c5c;
$btn-new-azure: rgba(19, 183, 208, 0.06);
$dashboard-box-shadow: 0 2px 4px 2px rgba($table-text-color, 0.3);
$filter-drop-shadow: drop-shadow(0 0 4px rgba($table-text-color, 0.3));
$day-appointment-toogle-bg: 0 -5px 4px 2px rgba($table-text-color, 0.3);
$appointment-nav-box-shadow: 0 6px 4px 2px rgba($table-text-color, 0.3);
$patient-files-templates-menu-item: 0 4px 8px 2px rgba($table-text-color, 0.3);
$border-line: #cccccc;

/* MIXINS */
$white: #ffffff;
$black: #000000;
$black-five: rgba(0, 0, 0, 0.8);
$near-white: #f3f3f3;
$off-white: #d8d8d8;
$pale-grey: #e5e4e9;
$dusty-lavender: #adabad;
$greyish-brown: #3e3d3a;
$light-periwinkle: #c5d0f9;
$light-periwinkle-20: rgba(197, 208, 249, 0.2);
$cerulean: #008bd2;
$soft-blue: #597bef;
$azure: #13b7e4;
$azure2: rgb(200, 231, 240);
$lavender-blue: #8aa1f3;
$yellow: #fff9dc;
$lightblue: #99aef3;

/* Terms agreement */
$no-agreement: #ff6d6d;
$agreement: #f8da44;
$agreement-and-doctor: #06d65c;

/* BORDERS */
$border-compare-top: #f8da44;

/* ICONS BG */
$btn-green: #00cd9c;

/* Dashboard appointment */
$no-show: $greyish-brown;
$checked-in: #06d65c;
$checked-in-text: #00cd9c;

/* PATIENT FILES */
$patient-colors: (
    assessment: #f3f3f3,
    medical-history: #f8da44,
    examination: #ffc764,
    diagnostic-results: #f8a744,
    diagnosis-management: #f87e44,
    summary: #f86144,
    addendum: #f3f3f3,
    report: #fb86b9,
    referral: #ffb4d5,
    diagnostics-request: #a6aefd,
    // prescription: #6e91f7,
    prescription: #9d6ef7,
    invoice: #1d93e3,
    attachments-alerts: #28c8f4,
    templates: #00cd9c,
    patient-files: #6587fb,
    warn-icon: $white,
    prescription-builder: #9d6ef7,
    billing: #b9d925,
    files: #28c8f4,
    screening: #f86144,
    immunisation: #f8da44,
);

$letters: "a" "b" "c" "d" "e" "f" "g" "h" "i" "j" "k" "l" "m" "n" "o" "p" "q" "r" "s" "t" "u" "v" "w" "x" "y" "z";

$plus: #6587fb;
/* TAGS */
$tag-close-color: #6587fb;

/* OPTIONS */
$option-header: rgba(94, 94, 94, 0.72);
$option-title: #597bef;

/* TABLE */
$table-border-color: rgba(94, 94, 94, 0.2);

/* DATEPICKER */
.mat-datepicker-toggle {
    .mat-icon-button {
        width: auto;
        height: auto;

        .mat-button-wrapper {
            &:before {
                content: '\e824';
                font-size: 24px;
                font-family: $fontello;
                font-style: normal;
                font-weight: normal;
                // speak: none;
                display: inline-block;
                text-decoration: inherit;
                width: 1em;
                margin-right: 0.2em;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                line-height: 1em;
                margin-left: 0.2em;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            .mat-datepicker-toggle-default-icon {
                display: none;
            }
        }
    }
}

/* BUTTONS */
button.mat-fab.btn-add {
    background: $white;
    border-color: $white;
    color: $table-text-color;
    width: 32px;
    height: 32px;

    span {
        padding: 0;
        margin-left: -2px;
        line-height: 30px;

        i {
            vertical-align: unset;

            &:before {
                font-size: 30px;
                margin-left: 1.5px;
            }
        }
    }
}

/* BUTTONS */
button.btn-blue {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px $option-title;
    background-color: $option-title;

    color: #fff;
    max-height: 38px;
}

/* INPUT */
.mat-input-element {
    font-family: $font-montserrat;
}

/* SELECT */
.mat-select {
    .mat-select-value {
        font-family: $font-montserrat;
    }

    .mat-select-arrow {
        width: auto !important;
        height: auto !important;
        border-top: 0 !important;
        border-left: 1px solid rgba(0, 0, 0, 0.54) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
        border-right: 0 !important;
        margin-bottom: 3px;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
}
/*profile header*/
$profile-icon-shadow: 2px -3px 25px -9px #2b2b2b;
$avatar-webkit-shadow: -2px -3px 25px -9px rgba(43, 43, 43, 1);
$avatar-moz-shadow: 2px -3px 25px -9px rgba(43, 43, 43, 1);
$avatar-box-shadow: 2px -3px 25px -9px rgba(43, 43, 43, 1);

$colors: #13b7e4 #9bc516 #ef76e8 #597bef #79e2ff #bfea33 #ffa4fa #a6aefd #d7f5fe #e2ff85 #ffe7fe #c9d5ff #597bef #13b7e4
    #ff5883 #ef76e8 #a6aefd #79e2ff #ffa8be #ffa4fa #c9d5ff #d7f5fe #ffdddf #ffe7fe #ef76e8 #597bef;

$safari-dialog-fix: app-patient-agreement-status app-appointment-confirmation app-demographics
    app-patient-detail-dialog app-history app-summary app-colleague-detail-dialog;
