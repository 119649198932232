@import './variables.scss';

#close-btn {
    cursor: pointer;
    position: absolute;
    top: 11px;
    right: 10px;
    width: 40px;
    height: 40px;
    background: none;
    border: 0px;
    z-index: 1;
    color: $primary;

    // &:hover {
    //     color: black;
    // }
}
