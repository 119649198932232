/* Typography */
.montserrat {
    font-family: "Montserrat", sans-serif;
}

.text-xsmall {
    font-size: 12px;
}

.text-small {
    font-size: 14px;
}

.text-medium {
    font-size: 18px;
}

.text-medium-large {
    font-size: 20px;
}

.text-large {
    font-size: 24px;
}

.text-light {
    font-weight: 300;
}

.text-regular {
    font-weight: 400;
}

.text-regular-heavy {
    font-weight: 500;
}

.text-semibold {
    font-weight: 500;
}

.text-bold {
    font-weight: 700;
}

.text-extrabold {
    font-weight: 800;
}

.text-break {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.text-break-word {
    word-break: break-all;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-primary {
    color: $primary;
}

/* Height and width adjustments */

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.padded-width {
    width: 90%;
}

.almost-full-width {
    width: 98%;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-30 {
    margin-left: 30px;
}

.margin-left-50 {
    margin-left: 50px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-30 {
    margin-right: 30px;
}

.margin-right-50 {
    margin-right: 50px;
}

.text-center {
    text-align: center;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-white {
    color: $white;
}

.text-black {
    color: $black;
}

.text-blue {
    color: $primary;
}

.text-grey {
    color: $table-text-color;
}

.text-greyish-brown {
    color: $greyish-brown;
}

.text-solid-line {
    color: $solid-line-color;
}

.soft-blue-tint {
    background-color: $primary;
    background-blend-mode: multiply;
}

.no-underline {
    text-decoration: none;
}

.separator-line {
    opacity: 0.3;
    border: solid 0.75px $solid-line-color;
}

.fm-clickable {
    cursor: pointer;
}

/* same as "img-responsive" in bootstrap */
body {
    .img-responsive {
        display: block;
        max-width: 100%;
        height: auto;
    }
}

.left-15-px-padding {
    padding-left: 15px;
}

.left-25-px-padding {
    padding-left: 25px;
}

.right-15-px-padding {
    padding-right: 15px;
}

.right-25-px-padding {
    padding-right: 25px;
}

.bottom-5-px-margin {
    margin-bottom: 5px;
}

.bottom-10-px-margin {
    margin-bottom: 10px;
}

.bottom-20-px-margin {
    margin-bottom: 20px;
}

.warning-text {
    color: $warn;
}

/* compatibility */
/* svg scaling on ie */
/* .ie9 img[src$=".svg"] {
    width: 100%;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    img[src$=".svg"] {
        width: 100%;
    }
} */
/* Mixins */
@mixin usmall {
    @media (max-width: 350px) {
        @content;
    }
}

@mixin xsmall {
    @media (min-width: 351px) and (max-width: 600px) {
        @content;
    }
}

@mixin small {
    @media (min-width: 601px) and (max-width: 960px) {
        @content;
    }
}

@mixin medium {
    @media (min-width: 961px) and (max-width: 1280px) {
        @content;
    }
}

@mixin large {
    @media (min-width: 1281px) and (max-width: 1920px) {
        @content;
    }
}

@mixin xlarge {
    @media (min-width: 1921px) {
        @content;
    }
}

// https://material.io/guidelines/layout/responsive-ui.html
/* Utility functions */
@function px($size) {
    @return $size/1920 * 100vw;
}

@function py($size) {
    @return $size/1080 * 100vh;
}

@mixin opacity($value) {
    $IEValue: $value * 100;
    opacity: $value;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $IEValue + ")";
    filter: alpha(opacity=$IEValue);
}

@mixin grayscale($value) {
    -webkit-filter: #{"grayscale(#{$value})"};
    -moz-filter: #{"grayscale(#{$value})"};
    filter: #{"grayscale(#{$value})"};
}

@mixin mail-shadow() {
    -webkit-box-shadow: $sombra-mail;
    -moz-box-shadow: $sombra-mail;
    box-shadow: $sombra-mail;
}

// RADIUS
@mixin radius($value) {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    border-radius: $value;
}

@mixin radius_left($value) {
    -webkit-border-top-left-radius: $value;
    -webkit-border-bottom-left-radius: $value;
    -moz-border-radius-topleft: $value;
    -moz-border-radius-bottomleft: $value;
    border-top-left-radius: $value;
    border-bottom-left-radius: $value;
}

@mixin radius_left_right($left, $right) {
    -webkit-border-top-left-radius: $left;
    -webkit-border-bottom-left-radius: $left;
    -moz-border-radius-topleft: $left;
    -moz-border-radius-bottomleft: $left;
    border-top-left-radius: $left;
    border-bottom-left-radius: $left;
    -webkit-border-top-right-radius: $right;
    -webkit-border-bottom-right-radius: $right;
    -moz-border-radius-topright: $right;
    -moz-border-radius-bottomright: $right;
    border-top-right-radius: $right;
    border-bottom-right-radius: $right;
}

// ROTATE
@mixin rotate($deg: 90deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    -o-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin tabs() {
    ::ng-deep .tab-group.azure {
        .mat-tab-header .mat-tab-label,
        .mat-tab-nav-bar .mat-tab-label {
            position: relative;
            border-bottom: 2px solid transparent;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-image: linear-gradient(
                    to right,
                    rgba($table-text-color, 0.42) 0,
                    rgba($table-text-color, 0.42) 33%,
                    transparent 0
                );
                background-size: 5px 2px;
                background-repeat: repeat-x;
            }
        }

        .mat-tab-header .mat-tab-label.mat-tab-label-active,
        .mat-tab-nav-bar .mat-tab-label.mat-tab-label-active {
            color: $azure;
        }

        .mat-ink-bar {
            bottom: 2px;
            background-color: $azure;
        }

        .mat-tab-body-content {
            overflow: hidden;
        }
    }
}

@mixin dialog() {
    ::ng-deep html.cdk-global-scrollblock {
        overflow: hidden !important;
    }
}

@mixin dialog_header_color() {
    .header {
        background: $primary;
        overflow: auto;

        h2 {
            font-family: $font-montserrat;
            font-size: 20px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            padding: 0 30px;
            margin: 20px 0;
        }
    }

    #close-btn {
        top: 14px;
        color: #fff;
    }
}

/* SCROLLBAR */

/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
    // For Google Chrome
    ::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    ::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    ::-webkit-scrollbar-track {
        background: $background-color;
    }

    // For Internet Explorer
    body {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

@mixin fixChip($top) {
    position: relative;
    top: $top;
}

// igghera/floating-action-menu (https://github.com/igghera/floating-action-menu)
@mixin overrideFabStyle() {
    ::ng-deep .mfb-component__button--main,
    ::ng-deep .mfb-component__button--child {
        background-color: $azure !important;
    }

    ::ng-deep .mfb-component__child-icon,
    ::ng-deep .mfb-component__child-icon,
    ::ng-deep .mfb-component__main-icon--active,
    ::ng-deep .mfb-component__main-icon--resting,
    ::ng-deep .mfb-component__button--child {
        font-size: 30px !important;
    }
}

@mixin noBorders() {
    $directions: ("top", "bottom", "right", "left");

    @each $direction in $directions {
        ::ng-deep .no-border-#{$direction} {
            border-#{$direction}: none !important;
        }
    }
}

@mixin agreement_colors() {
    // AGREEMENT
    .icon.no-agreement {
        color: $no-agreement;
    }

    .icon.agreement {
        color: $agreement;
    }

    .icon.agreement-and-doctor {
        color: $agrement-and-doctor;
    }

    .icon-status {
        &:before {
            content: " ";
            font-size: 30px;
            width: 12px;
            height: 12px;
            @include radius(50%);
        }

        &.no-agreement:before {
            background: $user-dnd;
        }

        &.agreement:before {
            background: $user-away;
        }

        &.agreement-and-doctor:before {
            background: $user-online;
        }
    }
}

@mixin patient_files_colors() {
    // CHECKIN
    .disabled {
        @include opacity(0.5);
        pointer-events: none;
    }

    .line {
        // padding: 8px 0 8px 0px;
        // border-bottom: 1px solid $list-color;
        border-bottom: 1px dashed rgba($table-text-color, 0.32);
        position: relative;

        &.active {
            background: $near-white;

            .appointment-toogle-bg,
            .appointment-nav {
                display: block !important;
            }
        }

        // ALTERNATIVE
        &.show {
            background: $near-white;

            .appointment-toogle-bg,
            .appointment-nav {
                display: block !important;
            }
        }

        i {
            cursor: pointer;
        }

        .dashboard-primary {
            color: $primary;
            font-weight: 600;

            i {
                color: $primary;
            }
        }

        .checked-in {
            cursor: pointer;
            color: $checked-in-text;
            font-weight: 600;

            i {
                color: $checked-in;
            }
        }

        .noshow {
            i {
                color: rgba(94, 94, 94, 0.36);
            }
        }

        .no-show {
            color: $black-five;
            text-decoration: line-through;

            i {
                color: $no-show;
            }
        }
    }
}

@mixin engines($key, $expression) {
    #{$key}: $expression;
    -webkit-#{$key}: $expression;
    -moz-#{$key}: $expression;
    -ms-#{$key}: $expression;
    -o-#{$key}: $expression;
}

@mixin calendar-header() {
    .cal-header {
        margin-left: 0;
        padding: 6px 0px 10px 0;
        text-align: left;
        border: none;
        display: inline-flex;

        b {
            font-size: 18px;
            font-weight: 600;
            color: $black-five;
        }

        b,
        span {
            padding-left: 15px;
            color: $table-text-color;
        }

        span {
            opacity: 1;
            font-size: 12px;
            letter-spacing: 0.4px;
            color: $black-five;
            padding-top: 5px;
        }

        &.cal-today {
            background-color: rgba($azure, 0.44);
        }

        &.cal-past {
            @include opacity(0.5);
        }
    }
}

@mixin calendar-time-event() {
    .cal-time-events {
        overflow: auto;
        height: calc(100vh - 215px);

        .cal-day-columns {
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
        }
    }
}