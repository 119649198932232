#browser_alert {
    display: none;
    background: rgba(0, 0, 0, 0.32);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

#browser_alert.active {
    display: block;
    z-index: 10;
}

#browser_alert #popup {
    width: 100%;
    max-width: 875px;
    height: 435px;
    margin: auto;
    padding: 25px 0;
    margin-top: 10%;
    background-color: white;
}

#browser_alert img {
    width: 50px;
    height: auto;
}

#browser_alert p {
    font-family: 'Montserrat', sans-serif;
    color: #597bef;
    text-align: center;
}
#browser_alert p.warn-paragraph {
    font-size: 14px;
    margin-bottom: 25px;
    font-weight: bold;
    color: #ef76e8;
}
#browser_alert p.info-paragraph {
    font-size: 14px;
    font-weight: 500;
    color: #5e5e5e;
}
#browser_alert p.info-subscribe {
    font-size: 18px;
    font-weight: bold;
}

#browser_alert a {
    font-size: 12px;
    margin-top: 25px;
    text-align: center;
}

#browser_alert ul.flex-size {
    margin: 0;
    padding: 0 0 0 120px;
    list-style-type: none;
    height: 150px;
    margin-top: 83px;
}

#browser_alert li {
    display: inline-block;
    width: 24%;
}

#browser_alert li img {
    margin-left: 20px;
}

#browser_alert li:last-child img {
    margin-left: 10px;
}
