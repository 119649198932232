@import "./variables.scss";

.mat-raised-button {
    background-color: $accent;
    font-family: $font-montserrat;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: lowercase;
    box-shadow: 0px 1px 3px 0px $sombra-raised-btn-01, 0 1px 2px 0px $sombra-raised-btn-02;
}

button {
    font-family: $font-montserrat;
}

button.mat-button,
button.mat-raised-button {
    border-radius: 20px;
}

.mat-button {
    font-weight: bold;

    i {
        font-size: 32px;
    }

    &.primary {
        color: $azure;
        border: 2px solid $azure;
    }

    &.transparent {
        background: transparent;
        color: $azure;
    }

    &.azure {
        background: $azure;
        color: $white;
    }

    &.soft-blue {
        font-weight: 600;
        background: $primary;
        color: $white;
    }

    &:disabled {
        // @include opacity(0.5);
    }
}

button,
a.button {
    outline: inherit;

    &.mat-raised-button {
        border-radius: 2px;
        box-shadow: 0 2px 2px 0 $sombra-raised-btn-01, 0 0 2px 0 $sombra-raised-btn-02;
        background-color: $accent;
        color: $white;
        &:hover {
            box-shadow: 0 8px 8px 0 $sombra-raised-btn-01, 0 0 8px 0 $sombra-raised-btn-02;
        }
        &[disabled],
        &[disabled]:hover {
            box-shadow: 0 2px 2px 0 $sombra-raised-btn-01, 0 0 2px 0 $sombra-raised-btn-02;
        }
    }
    &.mat-fab {
        // min-width: 88px;    // duplicated
        // padding: 0 16px;    // duplicated
        // border-radius: 2px; // duplicated
        min-width: 0;          // ???
        padding: 0;            // ???
        border-radius: 50%;    // ???
        line-height: 36px;
        width: 56px;
        height: 56px;
        flex-shrink: 0;
        border: solid 2px $primary;
    }
    &.mat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab {
        &.mat-primary {
            background-color: $primary;
            color: $white;
        }
        &.mat-accent {
            border-color: $accent;
            background-color: $accent;
            color: $white;
        }
        &.mat-warn {
            border-color: $warn;
            background-color: $warn;
            color: $white;
        }
        &[disabled] {
            border-width: 0;
            background-color: transparent;
        }
    }
}

.active_link {
    position: relative;
    font-weight: 800;
    background-color: $white;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        top: -1px;
        right: 0px;
        border-style: solid;
        border-color: $accent;
        display: block;
        width: 0;
        border-width: 0px 22px 22px 0;
        /* border-color: transparent $white transparent transparent; */
        border-radius: 0 0 0 2px;
    }
    &:after {
        content: "";
        position: absolute;
        top: 10px;
        right: -6px;
        width: 32px;
        height: 1px;
        background: $sombra-raised-btn-01;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-box-shadow: $sombra-content-01, -$sombra-content-02;
        box-shadow: $sombra-content-01, $sombra-content-02, $sombra-content-03;
    }
    span:before {
        content: "";
        position: absolute;
        top: -15px;
        right: -18px;
        width: 36px;
        height: 30px;
        background: $table-row-border;
        transform: rotate(45deg);
    }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $table-text-color;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $table-text-color;
}

.inverse-button {
    background: none;
    color: $azure;
    border: none;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;

    &:hover {
        cursor: pointer;
    }
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background-color: $primary;
}
