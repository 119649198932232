.doctor-review-card {
    font-family: $font-montserrat;
    color: $black;

    .mat-card-header-text {
        margin: 0;
    }
    .mat-card-title {
        font-weight: 600;
    }
    .mat-card-subtitle {
        color: $black;
    }
}

