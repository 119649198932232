@import './variables.scss';
@import './mixins-utils.scss';

/* INPUT */
.mat-input-element {
    font-family: $font-montserrat;
}

.mat-select-panel {
    max-height: 191px !important;
}

/* SELECT */
.mat-select {
    .mat-select-value {
        font-family: $font-montserrat;
    }

    .mat-select-arrow {
        width: auto !important;
        height: auto !important;
        border-top: 0 !important;
        border-left: 1px solid rgba(0, 0, 0, 0.54) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
        border-right: 0 !important;
        margin-bottom: 3px;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(80px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.effect-fadein {
    animation-name: fadeInLeft;
    animation-duration: 0.9s;
    display: block;
}

/* IE */
:-ms-fullscreen,
:root {
    [class*='flex'] {
        flex-basis: auto;
    }
}

/* general styles */
.cdk-overlay-dark-backdrop {
    // background: rgba($popup-background, 0.72);

    &.transparent {
        background-color: transparent;
    }
}

* {
    word-break: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*:focus {
    outline: none;
}

ins {
    text-decoration-color: $accent;
}

del {
    text-decoration-color: $warn;
}

[class^='icon-']:before,
[class*=' icon-']:before {
    cursor: pointer;
}

body {
    font-family: $font-montserrat !important;
    height: 100%;
    margin: 0;
    font-size: 14px;
    color: $primary;
    font-weight: 400;

    .mat-drawer-container {
        height: 100vh;
        height: -webkit-fill-available;
        height: var(--vh, 100vh);
    }

    :not([class^='mat-']),
    :not([class*=' mat-']) {
        font-family: $font-montserrat !important;
    }

    [class^='mat-'],
    [class*=' mat-'] {
        font-family: $font-montserrat;
    }

    [class^='mat-dialog-container'] {
        border-radius: 0;
    }

    .material-icons {
        font-family: 'Material Icons', sans-serif;
        font-weight: normal;
        font-style: normal;
        // font-size: 24px; // duplicated
        /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;

        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;

        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;

        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;

        /* Support for IE. */
        font-feature-settings: 'liga';

        /* changed  */
        font-size: 18px;
    }

    /* Headings */
    h1 {
        font-weight: 700;
        font-size: 24px;
    }

    h2 {
        font-size: 24px;
        font-weight: 200;
        letter-spacing: 0.4px;
        line-height: 1.5;
    }

    h3 {
        font-weight: 700;
        font-size: 18px;

        i {
            font-size: 30px;
        }
    }

    h4 {
        font-weight: 700;
        font-size: 16px;
    }

    h5 {
        font-weight: 700;
        font-size: 14px;
    }

    /* Links */
    a {
        color: $primary;

        &[href^='mailto:'] {
            text-decoration: none;
        }

        i {
            font-size: 30px;
        }
    }

    i {
        // font-size: 30px;

        &[class^='icon-']:before,
        &[class*=' icon-']:before {
            font-size: 30px;
        }
    }
}

.uppercase {
    text-transform: uppercase;
}

.mat-icon {
    cursor: pointer;
}

.mat-tab-body {
    z-index: 0;
}

.mat-tab-body.mat-tab-body-active {
    height: 100%;
}

table {
    tr.tblhdr {
        background: $border-line;
    }
}

pre,
xmp,
plaintext,
listing {
    font-family: $font-montserrat;
}

pre {
    word-wrap: break-word;
    white-space: normal;
}

/* MATERIAL */

::ng-deep .mat-standard-chip {
    height: auto;
}

.dark-form {
    .page {
        background-color: $azure;

        .header {
            color: $near-white;

            .logo {
                background-color: $primary;
            }

            .logo-font {
                background-color: $primary;
            }
        }

        .sub-header {
            background-color: $dark;
            color: $near-white;
        }
    }

    .mat-form-field-appearance-legacy .mat-form-field-label {
        color: $near-white;
        font-weight: normal;
    }

    .mat-form-field.mat-form-field-invalid .mat-form-field-label,
    .mat-error {
        color: rgb(244, 67, 54);
    }

    .mat-form-field .mat-form-field-underline {
        background-color: $near-white !important;
    }

    .mat-input-element,
    .mat-datepicker-toggle {
        color: $near-white;
    }

    /* SELECT */
    .mat-select {
        .mat-select-arrow {
            border-color: $near-white !important;
        }

        .mat-select-value,
        .mat-select-placeholder {
            color: $near-white;
        }
    }

    mat-card {
        background-color: $dark;
        color: $white;
    }

    .mat-stepper-horizontal,
    .mat-stepper-vertical {
        background-color: $dark;

        mat-expansion-panel {
            background-color: $dark;
            color: $white;

            mat-panel-title {
                color: $white;
            }
        }
    }

    .card-number,
    .card-expiry,
    .card-cvc {
        color: $near-white;
    }
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
    background-color: transparent;

    .mat-step-header:hover {
        background-color: transparent !important;
    }

    .mat-step-header.cdk-program-focused {
        background-color: transparent;
    }

    .mat-step-header.cdk-mouse-focused {
        background-color: transparent;
    }

    .mat-step-header.cdk-focused {
        background-color: transparent;
    }

    .mat-horizontal-content-container {
        padding: 0 0.25rem !important;
        margin-bottom: 70px;
        padding-bottom: 10px !important;
    }

    mat-expansion-panel {
        background-color: $white;
        box-shadow: 0 1px 3px 0 rgba($black, 0.2), 0 2px 1px -1px rgba($black, 0.12), 0 1px 1px 0 rgba($black, 0.14);

        .mat-expansion-indicator:after {
            color: $primary;
        }

        mat-panel-title {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.18px;
        }

        @media screen and (max-width: 575px) {
            mat-panel-title {
                font-size: 12px;
            }
        }
    }

    .mat-step-header {
        padding-left: 0px;
        padding-right: 0px;

        .mat-step-icon {
            height: 40px;
            width: 40px;
            background: #acbdf7;
            font-weight: 600;
            color: $black-five;

            &.mat-step-icon-selected {
                background: $primary;
                color: $white;
            }
        }
    }
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
    font: 400 16px/24px $font-montserrat;
    margin: 0 0 16px;
}

.mat-datepicker-toggle-active {
    color: $primary;
}

/* .mat-step-header .mat-step-icon {
  height: 32px;
  width: 32px;
  background-color: $primary;
  color: $white;
}

.mat-step-header .mat-step-icon-not-touched {
  width: 32px;
  color: $dusty-lavender;
  background-color: $near-white;
  box-shadow: 0 2px 4px 0 rgba(94, 94, 94, 0.36);
}
 */
.mat-dialog-container {
    background: $white;
    color: rgba(0, 0, 0, 0.87);
    /* padding: 0px !important; */

    .mat-dialog-content {
        padding-top: 1px;
        padding-bottom: 1px;
        max-height: 100%;
    }
}

.mat-datepicker-dialog .mat-dialog-container {
    position: relative;
    overflow: visible;
}

.mat-datepicker-content .mat-datepicker-close-button {
    display: none;
}

.mat-expansion-panel-header {
    font-size: 14px;
}

.mat-expansion-indicator {
    &:after {
        // .icon-fm-navigation-dropdown
        content: '\e86b' !important;
        border-width: 0 !important;
        padding: 6px 0 0 !important;
        transform: none !important;

        font-family: 'fontello', sans-serif;
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: 0.2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: 0.2em;
        font-size: 30px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

mat-sidenav#custom-mat-sidenav {
    min-width: 200px;
    width: 15%;
    background: $white;
    box-shadow: 0px 0px 7px 3px #dedede;

    a {
        font-size: 14px;
        font-weight: bold;
        color: $primary;
    }

    .active_route {
        font-size: 14px;
        font-weight: bold;
        color: $table-text-color;
    }

    a,
    .option {
        cursor: pointer;
        color: $primary;
    }
}

.mat-step-label {
    min-width: 0px !important;
}

/* MAT SIDEBAR */
.mat-drawer-side {
    border-right: none;
}

/* mat toggle */
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: $azure;
    opacity: 0.5;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: $azure;
}

/* SCROLL */
@include scrollbars(5px, mix($primary, white, 50%), $near-white);

.max-component-limit {
    margin: auto;
    max-width: 1328px;
}

/* ICONS */
.icon-fm-trash:before {
    font-size: 23px !important;
    line-height: 1em important;
    margin-top: 0.17em;
}

/* TAGS */
.mat-standard-chip .mat-chip-remove.mat-icon {
    color: $tag-close-color !important;
}

// AUTOCOMPLETE PANEL BIGGER WIDTH
.mat-autocomplete-panel.autocomplete-width {
    width: 340px;
    min-width: 340px;
    max-width: 340px;
    overflow-x: visible;
    overflow-y: auto;
}

.mat-card {
    margin: 0.25rem;
    border-radius: 0 !important;
}

button.add {
    background: none;
    border: none;
    border-radius: 8px;
    padding: 15px;
    font-size: 14px;
    font-weight: bold;
    color: $azure;
    transition: background-color 400ms ease-in;

    &:hover {
        cursor: pointer;
        background-color: $near-white;
    }

    &:disabled {
        color: $table-text-color;
    }
}

.hint {
    font-size: 12px;
    font-weight: normal;
    color: $black-five;
}

.pre_white_space {
    white-space: pre;
}

.pre-line_white_space {
    white-space: pre-line;
}

.mat-radio-button .mat-radio-label {
    white-space: initial;
}

// Input number disable default arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.mat-form-field-prefix {
    padding-right: 5px;
}

.bordered-box {
    padding: 5px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 1px 1px 3px 0 rgba($black, 0.2), 0 2px 1px -1px rgba($black, 0.12), 0 1px 1px 0 rgba($black, 0.14);

    &.selected {
        background-color: $selected;

        .icon-fm-check {
            &:before {
                background-color: $primary;
                color: $white;
                font-size: 18px;
                font-weight: bold;
                padding: 4px 3px 3px 3px;
                border-radius: 50%;
            }

            &.icon-top {
                z-index: 999;
                top: 6px;
                position: absolute;
                right: 10px;
            }

            &.icon-small:before {
                font-size: 15px;
            }
        }
    }
}

// CSS for fixed header
.page {
    background-color: $background-content;

    display: block;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-height: var(--vh, 100vh);
    height: 100vh;
    height: -webkit-fill-available;
    height: var(--vh, 100vh);
    position: relative;
    overflow: auto;
    overflow-x: hidden;

    &.bg-white {
        background-color: $white;
    }

    &.action-buttons {
        min-height: calc(100vh - 50px);
        min-height: var(--px, calc(100vh - 50px));
        height: calc(100vh - 50px);
        height: var(--px, calc(100vh - 50px));
    }

    .header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: 1;
        height: $header-height;
        background-color: white;
        color: $dark;

        .logo {
            width: 60px;
            cursor: pointer;
        }

        .logo-font {
            width: 170px;
            cursor: pointer;

            @media screen and (max-width: 575px) {
                display: none;
            }
        }

        .mat-icon {
            color: $azure;
            font-size: 25px;
            line-height: 35px;
        }
    }

    .sub-header {
        color: $black-five;
        min-height: calc(100% - #{$header-height});
        max-width: 100%;
        width: 100%;
        z-index: 0;
        position: absolute;
        top: $header-height;

        @media screen and (max-width: 575px) {
            padding-bottom: 70px;
        }

        @media screen and (min-width: 992px) {
            .container {
                margin-left: 0;
                @include make-container-width();
            }
        }
    }
}

.no_authentication .page .sub-header .container {
    margin-left: auto;
    @include make-container-width(max-width, 0px);
}

.no-padding mat-dialog-container {
    padding: 0;
}

.fm_menu,
.user_menu {
    background-color: white;
    color: $primary;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-height: var(--vh, 100vh);

    ul.links {
        list-style-type: none;
        padding-left: 0;

        li {
            white-space: nowrap;
            margin: 30px 0;
            padding: 0 15px;

            a {
                font-size: 12px;
                font-weight: 600;
                line-height: 1.29;
                letter-spacing: 1.25px;
                text-decoration: none;
                text-transform: uppercase;
                cursor: pointer;
                padding: 0 30px 0 0;

                &:hover,
                &.active {
                    color: $azure;
                }
            }
        }
    }
}

.ucfirst::first-letter {
    text-transform: uppercase;
}

.fm-tooltip {
    max-width: unset !important;
}
