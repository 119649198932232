@charset "UTF-8";
/* FONTS */
/* HEADER */
/* FOOTER */
/* TABLE */
/* MAIN COLORS */
/* USER STATUS */
/* COLORS */
/* SHADOWS */
/* MIXINS */
/* Terms agreement */
/* BORDERS */
/* ICONS BG */
/* Dashboard appointment */
/* PATIENT FILES */
/* TAGS */
/* OPTIONS */
/* TABLE */
/* DATEPICKER */
.mat-datepicker-toggle .mat-icon-button {
  width: auto;
  height: auto;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper:before {
  content: "\e824";
  font-size: 24px;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper .mat-datepicker-toggle-default-icon {
  display: none;
}

/* BUTTONS */
button.mat-fab.btn-add {
  background: #ffffff;
  border-color: #ffffff;
  color: #5e5e5e;
  width: 32px;
  height: 32px;
}
button.mat-fab.btn-add span {
  padding: 0;
  margin-left: -2px;
  line-height: 30px;
}
button.mat-fab.btn-add span i {
  vertical-align: unset;
}
button.mat-fab.btn-add span i:before {
  font-size: 30px;
  margin-left: 1.5px;
}

/* BUTTONS */
button.btn-blue {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #597bef;
  background-color: #597bef;
  color: #fff;
  max-height: 38px;
}

/* INPUT */
.mat-input-element {
  font-family: "Montserrat", sans-serif;
}

/* SELECT */
.mat-select .mat-select-value {
  font-family: "Montserrat", sans-serif;
}
.mat-select .mat-select-arrow {
  width: auto !important;
  height: auto !important;
  border-top: 0 !important;
  border-left: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-right: 0 !important;
  margin-bottom: 3px;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/*profile header*/
/* Typography */
.montserrat {
  font-family: "Montserrat", sans-serif;
}

.text-xsmall {
  font-size: 12px;
}

.text-small {
  font-size: 14px;
}

.text-medium {
  font-size: 18px;
}

.text-medium-large {
  font-size: 20px;
}

.text-large {
  font-size: 24px;
}

.text-light {
  font-weight: 300;
}

.text-regular {
  font-weight: 400;
}

.text-regular-heavy {
  font-weight: 500;
}

.text-semibold {
  font-weight: 500;
}

.text-bold {
  font-weight: 700;
}

.text-extrabold {
  font-weight: 800;
}

.text-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.text-break-word {
  word-break: break-all;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: #597bef;
}

/* Height and width adjustments */
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.padded-width {
  width: 90%;
}

.almost-full-width {
  width: 98%;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-50 {
  margin-right: 50px;
}

.text-center {
  text-align: center;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.text-blue {
  color: #597bef;
}

.text-grey {
  color: #5e5e5e;
}

.text-greyish-brown {
  color: #3e3d3a;
}

.text-solid-line {
  color: rgba(94, 94, 94, 0.72);
}

.soft-blue-tint {
  background-color: #597bef;
  background-blend-mode: multiply;
}

.no-underline {
  text-decoration: none;
}

.separator-line {
  opacity: 0.3;
  border: solid 0.75px rgba(94, 94, 94, 0.72);
}

.fm-clickable {
  cursor: pointer;
}

/* same as "img-responsive" in bootstrap */
body .img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.left-15-px-padding {
  padding-left: 15px;
}

.left-25-px-padding {
  padding-left: 25px;
}

.right-15-px-padding {
  padding-right: 15px;
}

.right-25-px-padding {
  padding-right: 25px;
}

.bottom-5-px-margin {
  margin-bottom: 5px;
}

.bottom-10-px-margin {
  margin-bottom: 10px;
}

.bottom-20-px-margin {
  margin-bottom: 20px;
}

.warning-text {
  color: #ef76e8;
}

/* compatibility */
/* svg scaling on ie */
/* .ie9 img[src$=".svg"] {
    width: 100%;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    img[src$=".svg"] {
        width: 100%;
    }
} */
/* Mixins */
/* Utility functions */
/* SCROLLBAR */
/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  box-sizing: inherit;
}

textarea {
  box-sizing: initial;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1024px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1280px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1367px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1280px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1367px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1280px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1367px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-icon {
  margin: 70px !important;
}

.mt-icon,
.my-icon {
  margin-top: 70px !important;
}

.mr-icon,
.mx-icon {
  margin-right: 70px !important;
}

.mb-icon,
.my-icon {
  margin-bottom: 70px !important;
}

.ml-icon,
.mx-icon {
  margin-left: 70px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-icon {
  padding: 70px !important;
}

.pt-icon,
.py-icon {
  padding-top: 70px !important;
}

.pr-icon,
.px-icon {
  padding-right: 70px !important;
}

.pb-icon,
.py-icon {
  padding-bottom: 70px !important;
}

.pl-icon,
.px-icon {
  padding-left: 70px !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-nicon {
  margin: -70px !important;
}

.mt-nicon,
.my-nicon {
  margin-top: -70px !important;
}

.mr-nicon,
.mx-nicon {
  margin-right: -70px !important;
}

.mb-nicon,
.my-nicon {
  margin-bottom: -70px !important;
}

.ml-nicon,
.mx-nicon {
  margin-left: -70px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .m-sm-icon {
    margin: 70px !important;
  }

  .mt-sm-icon,
.my-sm-icon {
    margin-top: 70px !important;
  }

  .mr-sm-icon,
.mx-sm-icon {
    margin-right: 70px !important;
  }

  .mb-sm-icon,
.my-sm-icon {
    margin-bottom: 70px !important;
  }

  .ml-sm-icon,
.mx-sm-icon {
    margin-left: 70px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .p-sm-icon {
    padding: 70px !important;
  }

  .pt-sm-icon,
.py-sm-icon {
    padding-top: 70px !important;
  }

  .pr-sm-icon,
.px-sm-icon {
    padding-right: 70px !important;
  }

  .pb-sm-icon,
.py-sm-icon {
    padding-bottom: 70px !important;
  }

  .pl-sm-icon,
.px-sm-icon {
    padding-left: 70px !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-nicon {
    margin: -70px !important;
  }

  .mt-sm-nicon,
.my-sm-nicon {
    margin-top: -70px !important;
  }

  .mr-sm-nicon,
.mx-sm-nicon {
    margin-right: -70px !important;
  }

  .mb-sm-nicon,
.my-sm-nicon {
    margin-bottom: -70px !important;
  }

  .ml-sm-nicon,
.mx-sm-nicon {
    margin-left: -70px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .m-md-icon {
    margin: 70px !important;
  }

  .mt-md-icon,
.my-md-icon {
    margin-top: 70px !important;
  }

  .mr-md-icon,
.mx-md-icon {
    margin-right: 70px !important;
  }

  .mb-md-icon,
.my-md-icon {
    margin-bottom: 70px !important;
  }

  .ml-md-icon,
.mx-md-icon {
    margin-left: 70px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .p-md-icon {
    padding: 70px !important;
  }

  .pt-md-icon,
.py-md-icon {
    padding-top: 70px !important;
  }

  .pr-md-icon,
.px-md-icon {
    padding-right: 70px !important;
  }

  .pb-md-icon,
.py-md-icon {
    padding-bottom: 70px !important;
  }

  .pl-md-icon,
.px-md-icon {
    padding-left: 70px !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-nicon {
    margin: -70px !important;
  }

  .mt-md-nicon,
.my-md-nicon {
    margin-top: -70px !important;
  }

  .mr-md-nicon,
.mx-md-nicon {
    margin-right: -70px !important;
  }

  .mb-md-nicon,
.my-md-nicon {
    margin-bottom: -70px !important;
  }

  .ml-md-nicon,
.mx-md-nicon {
    margin-left: -70px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .m-lg-icon {
    margin: 70px !important;
  }

  .mt-lg-icon,
.my-lg-icon {
    margin-top: 70px !important;
  }

  .mr-lg-icon,
.mx-lg-icon {
    margin-right: 70px !important;
  }

  .mb-lg-icon,
.my-lg-icon {
    margin-bottom: 70px !important;
  }

  .ml-lg-icon,
.mx-lg-icon {
    margin-left: 70px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .p-lg-icon {
    padding: 70px !important;
  }

  .pt-lg-icon,
.py-lg-icon {
    padding-top: 70px !important;
  }

  .pr-lg-icon,
.px-lg-icon {
    padding-right: 70px !important;
  }

  .pb-lg-icon,
.py-lg-icon {
    padding-bottom: 70px !important;
  }

  .pl-lg-icon,
.px-lg-icon {
    padding-left: 70px !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-nicon {
    margin: -70px !important;
  }

  .mt-lg-nicon,
.my-lg-nicon {
    margin-top: -70px !important;
  }

  .mr-lg-nicon,
.mx-lg-nicon {
    margin-right: -70px !important;
  }

  .mb-lg-nicon,
.my-lg-nicon {
    margin-bottom: -70px !important;
  }

  .ml-lg-nicon,
.mx-lg-nicon {
    margin-left: -70px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1280px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .m-xl-icon {
    margin: 70px !important;
  }

  .mt-xl-icon,
.my-xl-icon {
    margin-top: 70px !important;
  }

  .mr-xl-icon,
.mx-xl-icon {
    margin-right: 70px !important;
  }

  .mb-xl-icon,
.my-xl-icon {
    margin-bottom: 70px !important;
  }

  .ml-xl-icon,
.mx-xl-icon {
    margin-left: 70px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .p-xl-icon {
    padding: 70px !important;
  }

  .pt-xl-icon,
.py-xl-icon {
    padding-top: 70px !important;
  }

  .pr-xl-icon,
.px-xl-icon {
    padding-right: 70px !important;
  }

  .pb-xl-icon,
.py-xl-icon {
    padding-bottom: 70px !important;
  }

  .pl-xl-icon,
.px-xl-icon {
    padding-left: 70px !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-nicon {
    margin: -70px !important;
  }

  .mt-xl-nicon,
.my-xl-nicon {
    margin-top: -70px !important;
  }

  .mr-xl-nicon,
.mx-xl-nicon {
    margin-right: -70px !important;
  }

  .mb-xl-nicon,
.my-xl-nicon {
    margin-bottom: -70px !important;
  }

  .ml-xl-nicon,
.mx-xl-nicon {
    margin-left: -70px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1367px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .m-xxl-icon {
    margin: 70px !important;
  }

  .mt-xxl-icon,
.my-xxl-icon {
    margin-top: 70px !important;
  }

  .mr-xxl-icon,
.mx-xxl-icon {
    margin-right: 70px !important;
  }

  .mb-xxl-icon,
.my-xxl-icon {
    margin-bottom: 70px !important;
  }

  .ml-xxl-icon,
.mx-xxl-icon {
    margin-left: 70px !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 3rem !important;
  }

  .p-xxl-icon {
    padding: 70px !important;
  }

  .pt-xxl-icon,
.py-xxl-icon {
    padding-top: 70px !important;
  }

  .pr-xxl-icon,
.px-xxl-icon {
    padding-right: 70px !important;
  }

  .pb-xxl-icon,
.py-xxl-icon {
    padding-bottom: 70px !important;
  }

  .pl-xxl-icon,
.px-xxl-icon {
    padding-left: 70px !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-nicon {
    margin: -70px !important;
  }

  .mt-xxl-nicon,
.my-xxl-nicon {
    margin-top: -70px !important;
  }

  .mr-xxl-nicon,
.mx-xxl-nicon {
    margin-right: -70px !important;
  }

  .mb-xxl-nicon,
.my-xxl-nicon {
    margin-bottom: -70px !important;
  }

  .ml-xxl-nicon,
.mx-xxl-nicon {
    margin-left: -70px !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

/* FONTS */
/* HEADER */
/* FOOTER */
/* TABLE */
/* MAIN COLORS */
/* USER STATUS */
/* COLORS */
/* SHADOWS */
/* MIXINS */
/* Terms agreement */
/* BORDERS */
/* ICONS BG */
/* Dashboard appointment */
/* PATIENT FILES */
/* TAGS */
/* OPTIONS */
/* TABLE */
/* DATEPICKER */
.mat-datepicker-toggle .mat-icon-button {
  width: auto;
  height: auto;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper:before {
  content: "\e824";
  font-size: 24px;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper .mat-datepicker-toggle-default-icon {
  display: none;
}

/* BUTTONS */
button.mat-fab.btn-add {
  background: #ffffff;
  border-color: #ffffff;
  color: #5e5e5e;
  width: 32px;
  height: 32px;
}
button.mat-fab.btn-add span {
  padding: 0;
  margin-left: -2px;
  line-height: 30px;
}
button.mat-fab.btn-add span i {
  vertical-align: unset;
}
button.mat-fab.btn-add span i:before {
  font-size: 30px;
  margin-left: 1.5px;
}

/* BUTTONS */
button.btn-blue {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #597bef;
  background-color: #597bef;
  color: #fff;
  max-height: 38px;
}

/* INPUT */
.mat-input-element {
  font-family: "Montserrat", sans-serif;
}

/* SELECT */
.mat-select .mat-select-value {
  font-family: "Montserrat", sans-serif;
}
.mat-select .mat-select-arrow {
  width: auto !important;
  height: auto !important;
  border-top: 0 !important;
  border-left: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-right: 0 !important;
  margin-bottom: 3px;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/*profile header*/
/* Typography */
.montserrat {
  font-family: "Montserrat", sans-serif;
}

.text-xsmall {
  font-size: 12px;
}

.text-small {
  font-size: 14px;
}

.text-medium {
  font-size: 18px;
}

.text-medium-large {
  font-size: 20px;
}

.text-large {
  font-size: 24px;
}

.text-light {
  font-weight: 300;
}

.text-regular {
  font-weight: 400;
}

.text-regular-heavy {
  font-weight: 500;
}

.text-semibold {
  font-weight: 500;
}

.text-bold {
  font-weight: 700;
}

.text-extrabold {
  font-weight: 800;
}

.text-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.text-break-word {
  word-break: break-all;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: #597bef;
}

/* Height and width adjustments */
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.padded-width {
  width: 90%;
}

.almost-full-width {
  width: 98%;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-50 {
  margin-right: 50px;
}

.text-center {
  text-align: center;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.text-blue {
  color: #597bef;
}

.text-grey {
  color: #5e5e5e;
}

.text-greyish-brown {
  color: #3e3d3a;
}

.text-solid-line {
  color: rgba(94, 94, 94, 0.72);
}

.soft-blue-tint {
  background-color: #597bef;
  background-blend-mode: multiply;
}

.no-underline {
  text-decoration: none;
}

.separator-line {
  opacity: 0.3;
  border: solid 0.75px rgba(94, 94, 94, 0.72);
}

.fm-clickable {
  cursor: pointer;
}

/* same as "img-responsive" in bootstrap */
body .img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.left-15-px-padding {
  padding-left: 15px;
}

.left-25-px-padding {
  padding-left: 25px;
}

.right-15-px-padding {
  padding-right: 15px;
}

.right-25-px-padding {
  padding-right: 25px;
}

.bottom-5-px-margin {
  margin-bottom: 5px;
}

.bottom-10-px-margin {
  margin-bottom: 10px;
}

.bottom-20-px-margin {
  margin-bottom: 20px;
}

.warning-text {
  color: #ef76e8;
}

/* compatibility */
/* svg scaling on ie */
/* .ie9 img[src$=".svg"] {
    width: 100%;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    img[src$=".svg"] {
        width: 100%;
    }
} */
/* Mixins */
/* Utility functions */
/* SCROLLBAR */
/* INPUT */
.mat-input-element {
  font-family: "Montserrat", sans-serif;
}

.mat-select-panel {
  max-height: 191px !important;
}

/* SELECT */
.mat-select .mat-select-value {
  font-family: "Montserrat", sans-serif;
}
.mat-select .mat-select-arrow {
  width: auto !important;
  height: auto !important;
  border-top: 0 !important;
  border-left: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-right: 0 !important;
  margin-bottom: 3px;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.effect-fadein {
  animation-name: fadeInLeft;
  animation-duration: 0.9s;
  display: block;
}

/* IE */
:-ms-fullscreen [class*=flex],
:root [class*=flex] {
  flex-basis: auto;
}

/* general styles */
.cdk-overlay-dark-backdrop.transparent {
  background-color: transparent;
}

* {
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

ins {
  text-decoration-color: #13b7e4;
}

del {
  text-decoration-color: #ef76e8;
}

[class^=icon-]:before,
[class*=" icon-"]:before {
  cursor: pointer;
}

body {
  font-family: "Montserrat", sans-serif !important;
  height: 100%;
  margin: 0;
  font-size: 14px;
  color: #597bef;
  font-weight: 400;
  /* Headings */
  /* Links */
}
body .mat-drawer-container {
  height: 100vh;
  height: -webkit-fill-available;
  height: var(--vh, 100vh);
}
body :not([class^=mat-]),
body :not([class*=" mat-"]) {
  font-family: "Montserrat", sans-serif !important;
}
body [class^=mat-],
body [class*=" mat-"] {
  font-family: "Montserrat", sans-serif;
}
body [class^=mat-dialog-container] {
  border-radius: 0;
}
body .material-icons {
  font-family: "Material Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
  /* changed  */
  font-size: 18px;
}
body h1 {
  font-weight: 700;
  font-size: 24px;
}
body h2 {
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 0.4px;
  line-height: 1.5;
}
body h3 {
  font-weight: 700;
  font-size: 18px;
}
body h3 i {
  font-size: 30px;
}
body h4 {
  font-weight: 700;
  font-size: 16px;
}
body h5 {
  font-weight: 700;
  font-size: 14px;
}
body a {
  color: #597bef;
}
body a[href^="mailto:"] {
  text-decoration: none;
}
body a i {
  font-size: 30px;
}
body i[class^=icon-]:before, body i[class*=" icon-"]:before {
  font-size: 30px;
}

.uppercase {
  text-transform: uppercase;
}

.mat-icon {
  cursor: pointer;
}

.mat-tab-body {
  z-index: 0;
}

.mat-tab-body.mat-tab-body-active {
  height: 100%;
}

table tr.tblhdr {
  background: #cccccc;
}

pre,
xmp,
plaintext,
listing {
  font-family: "Montserrat", sans-serif;
}

pre {
  word-wrap: break-word;
  white-space: normal;
}

/* MATERIAL */
::ng-deep .mat-standard-chip {
  height: auto;
}

.dark-form {
  /* SELECT */
}
.dark-form .page {
  background-color: #13b7e4;
}
.dark-form .page .header {
  color: #f3f3f3;
}
.dark-form .page .header .logo {
  background-color: #597bef;
}
.dark-form .page .header .logo-font {
  background-color: #597bef;
}
.dark-form .page .sub-header {
  background-color: #141b36;
  color: #f3f3f3;
}
.dark-form .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #f3f3f3;
  font-weight: normal;
}
.dark-form .mat-form-field.mat-form-field-invalid .mat-form-field-label,
.dark-form .mat-error {
  color: rgb(244, 67, 54);
}
.dark-form .mat-form-field .mat-form-field-underline {
  background-color: #f3f3f3 !important;
}
.dark-form .mat-input-element,
.dark-form .mat-datepicker-toggle {
  color: #f3f3f3;
}
.dark-form .mat-select .mat-select-arrow {
  border-color: #f3f3f3 !important;
}
.dark-form .mat-select .mat-select-value,
.dark-form .mat-select .mat-select-placeholder {
  color: #f3f3f3;
}
.dark-form mat-card {
  background-color: #141b36;
  color: #ffffff;
}
.dark-form .mat-stepper-horizontal,
.dark-form .mat-stepper-vertical {
  background-color: #141b36;
}
.dark-form .mat-stepper-horizontal mat-expansion-panel,
.dark-form .mat-stepper-vertical mat-expansion-panel {
  background-color: #141b36;
  color: #ffffff;
}
.dark-form .mat-stepper-horizontal mat-expansion-panel mat-panel-title,
.dark-form .mat-stepper-vertical mat-expansion-panel mat-panel-title {
  color: #ffffff;
}
.dark-form .card-number,
.dark-form .card-expiry,
.dark-form .card-cvc {
  color: #f3f3f3;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: transparent;
}
.mat-stepper-horizontal .mat-step-header:hover,
.mat-stepper-vertical .mat-step-header:hover {
  background-color: transparent !important;
}
.mat-stepper-horizontal .mat-step-header.cdk-program-focused,
.mat-stepper-vertical .mat-step-header.cdk-program-focused {
  background-color: transparent;
}
.mat-stepper-horizontal .mat-step-header.cdk-mouse-focused,
.mat-stepper-vertical .mat-step-header.cdk-mouse-focused {
  background-color: transparent;
}
.mat-stepper-horizontal .mat-step-header.cdk-focused,
.mat-stepper-vertical .mat-step-header.cdk-focused {
  background-color: transparent;
}
.mat-stepper-horizontal .mat-horizontal-content-container,
.mat-stepper-vertical .mat-horizontal-content-container {
  padding: 0 0.25rem !important;
  margin-bottom: 70px;
  padding-bottom: 10px !important;
}
.mat-stepper-horizontal mat-expansion-panel,
.mat-stepper-vertical mat-expansion-panel {
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}
.mat-stepper-horizontal mat-expansion-panel .mat-expansion-indicator:after,
.mat-stepper-vertical mat-expansion-panel .mat-expansion-indicator:after {
  color: #597bef;
}
.mat-stepper-horizontal mat-expansion-panel mat-panel-title,
.mat-stepper-vertical mat-expansion-panel mat-panel-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.18px;
}
@media screen and (max-width: 575px) {
  .mat-stepper-horizontal mat-expansion-panel mat-panel-title,
.mat-stepper-vertical mat-expansion-panel mat-panel-title {
    font-size: 12px;
  }
}
.mat-stepper-horizontal .mat-step-header,
.mat-stepper-vertical .mat-step-header {
  padding-left: 0px;
  padding-right: 0px;
}
.mat-stepper-horizontal .mat-step-header .mat-step-icon,
.mat-stepper-vertical .mat-step-header .mat-step-icon {
  height: 40px;
  width: 40px;
  background: #acbdf7;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}
.mat-stepper-horizontal .mat-step-header .mat-step-icon.mat-step-icon-selected,
.mat-stepper-vertical .mat-step-header .mat-step-icon.mat-step-icon-selected {
  background: #597bef;
  color: #ffffff;
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
  font: 400 16px/24px "Montserrat", sans-serif;
  margin: 0 0 16px;
}

.mat-datepicker-toggle-active {
  color: #597bef;
}

/* .mat-step-header .mat-step-icon {
  height: 32px;
  width: 32px;
  background-color: $primary;
  color: $white;
}

.mat-step-header .mat-step-icon-not-touched {
  width: 32px;
  color: $dusty-lavender;
  background-color: $near-white;
  box-shadow: 0 2px 4px 0 rgba(94, 94, 94, 0.36);
}
 */
.mat-dialog-container {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  /* padding: 0px !important; */
}
.mat-dialog-container .mat-dialog-content {
  padding-top: 1px;
  padding-bottom: 1px;
  max-height: 100%;
}

.mat-datepicker-dialog .mat-dialog-container {
  position: relative;
  overflow: visible;
}

.mat-datepicker-content .mat-datepicker-close-button {
  display: none;
}

.mat-expansion-panel-header {
  font-size: 14px;
}

.mat-expansion-indicator:after {
  content: "\e86b" !important;
  border-width: 0 !important;
  padding: 6px 0 0 !important;
  transform: none !important;
  font-family: "fontello", sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

mat-sidenav#custom-mat-sidenav {
  min-width: 200px;
  width: 15%;
  background: #ffffff;
  box-shadow: 0px 0px 7px 3px #dedede;
}
mat-sidenav#custom-mat-sidenav a {
  font-size: 14px;
  font-weight: bold;
  color: #597bef;
}
mat-sidenav#custom-mat-sidenav .active_route {
  font-size: 14px;
  font-weight: bold;
  color: #5e5e5e;
}
mat-sidenav#custom-mat-sidenav a,
mat-sidenav#custom-mat-sidenav .option {
  cursor: pointer;
  color: #597bef;
}

.mat-step-label {
  min-width: 0px !important;
}

/* MAT SIDEBAR */
.mat-drawer-side {
  border-right: none;
}

/* mat toggle */
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #13b7e4;
  opacity: 0.5;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #13b7e4;
}

/* SCROLL */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #acbdf7;
}

::-webkit-scrollbar-track {
  background: #f3f3f3;
}

body {
  scrollbar-face-color: #acbdf7;
  scrollbar-track-color: #f3f3f3;
}

.max-component-limit {
  margin: auto;
  max-width: 1328px;
}

/* ICONS */
.icon-fm-trash:before {
  font-size: 23px !important;
  line-height: 1em important;
  margin-top: 0.17em;
}

/* TAGS */
.mat-standard-chip .mat-chip-remove.mat-icon {
  color: #6587fb !important;
}

.mat-autocomplete-panel.autocomplete-width {
  width: 340px;
  min-width: 340px;
  max-width: 340px;
  overflow-x: visible;
  overflow-y: auto;
}

.mat-card {
  margin: 0.25rem;
  border-radius: 0 !important;
}

button.add {
  background: none;
  border: none;
  border-radius: 8px;
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
  color: #13b7e4;
  transition: background-color 400ms ease-in;
}
button.add:hover {
  cursor: pointer;
  background-color: #f3f3f3;
}
button.add:disabled {
  color: #5e5e5e;
}

.hint {
  font-size: 12px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.8);
}

.pre_white_space {
  white-space: pre;
}

.pre-line_white_space {
  white-space: pre-line;
}

.mat-radio-button .mat-radio-label {
  white-space: initial;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mat-form-field-prefix {
  padding-right: 5px;
}

.bordered-box {
  padding: 5px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}
.bordered-box.selected {
  background-color: rgba(89, 123, 239, 0.08);
}
.bordered-box.selected .icon-fm-check:before {
  background-color: #597bef;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  padding: 4px 3px 3px 3px;
  border-radius: 50%;
}
.bordered-box.selected .icon-fm-check.icon-top {
  z-index: 999;
  top: 6px;
  position: absolute;
  right: 10px;
}
.bordered-box.selected .icon-fm-check.icon-small:before {
  font-size: 15px;
}

.page {
  background-color: rgba(235, 239, 253, 0.6);
  display: block;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: var(--vh, 100vh);
  height: 100vh;
  height: -webkit-fill-available;
  height: var(--vh, 100vh);
  position: relative;
  overflow: auto;
  overflow-x: hidden;
}
.page.bg-white {
  background-color: #ffffff;
}
.page.action-buttons {
  min-height: calc(100vh - 50px);
  min-height: var(--px, calc(100vh - 50px));
  height: calc(100vh - 50px);
  height: var(--px, calc(100vh - 50px));
}
.page .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  height: 64px;
  background-color: white;
  color: #141b36;
}
.page .header .logo {
  width: 60px;
  cursor: pointer;
}
.page .header .logo-font {
  width: 170px;
  cursor: pointer;
}
@media screen and (max-width: 575px) {
  .page .header .logo-font {
    display: none;
  }
}
.page .header .mat-icon {
  color: #13b7e4;
  font-size: 25px;
  line-height: 35px;
}
.page .sub-header {
  color: rgba(0, 0, 0, 0.8);
  min-height: calc(100% - 64px);
  max-width: 100%;
  width: 100%;
  z-index: 0;
  position: absolute;
  top: 64px;
}
@media screen and (max-width: 575px) {
  .page .sub-header {
    padding-bottom: 70px;
  }
}
@media screen and (min-width: 992px) {
  .page .sub-header .container {
    margin-left: 0;
  }
}
@media screen and (min-width: 992px) and (min-width: 576px) {
  .page .sub-header .container {
    max-width: calc(540px - 230px);
  }
}
@media screen and (min-width: 992px) and (min-width: 768px) {
  .page .sub-header .container {
    max-width: calc(720px - 230px);
  }
}
@media screen and (min-width: 992px) and (min-width: 992px) {
  .page .sub-header .container {
    max-width: calc(960px - 230px);
  }
}
@media screen and (min-width: 992px) and (min-width: 1280px) {
  .page .sub-header .container {
    max-width: calc(1024px - 230px);
  }
}

.no_authentication .page .sub-header .container {
  margin-left: auto;
}
@media (min-width: 576px) {
  .no_authentication .page .sub-header .container {
    max-width: calc(540px - 0px);
  }
}
@media (min-width: 768px) {
  .no_authentication .page .sub-header .container {
    max-width: calc(720px - 0px);
  }
}
@media (min-width: 992px) {
  .no_authentication .page .sub-header .container {
    max-width: calc(960px - 0px);
  }
}
@media (min-width: 1280px) {
  .no_authentication .page .sub-header .container {
    max-width: calc(1024px - 0px);
  }
}

.no-padding mat-dialog-container {
  padding: 0;
}

.fm_menu,
.user_menu {
  background-color: white;
  color: #597bef;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: var(--vh, 100vh);
}
.fm_menu ul.links,
.user_menu ul.links {
  list-style-type: none;
  padding-left: 0;
}
.fm_menu ul.links li,
.user_menu ul.links li {
  white-space: nowrap;
  margin: 30px 0;
  padding: 0 15px;
}
.fm_menu ul.links li a,
.user_menu ul.links li a {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 1.25px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 30px 0 0;
}
.fm_menu ul.links li a:hover, .fm_menu ul.links li a.active,
.user_menu ul.links li a:hover,
.user_menu ul.links li a.active {
  color: #13b7e4;
}

.ucfirst::first-letter {
  text-transform: uppercase;
}

.fm-tooltip {
  max-width: unset !important;
}

@font-face {
  font-family: "fontello";
  src: url("~/assets/fm-icons/fontello.eot?583380");
  src: url("~/assets/fm-icons/fontello.eot?583380#iefix") format("embedded-opentype"), url("~/assets/fm-icons/fontello.woff2?583380") format("woff2"), url("~/assets/fm-icons/fontello.woff?583380") format("woff"), url("~/assets/fm-icons/fontello.ttf?583380") format("truetype"), url("~/assets/fm-icons/fontello.svg?583380#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('~/assets/fm-icons/fontello.svg?46206205#fontello') format('svg');
  }
}
*/
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "fontello", sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-picture:before {
  content: "\e800";
}

/* '' */
.icon-picture:before {
  content: "\e801";
}

/* '' */
.icon-fm-print:before {
  content: "\e802";
}

/* '' */
.icon-fm-referral:before {
  content: "\e803";
}

/* '' */
.icon-fm-reffered-from:before {
  content: "\e804";
}

/* '' */
.icon-fm-reffer-to:before {
  content: "\e805";
}

/* '' */
.icon-fm-report:before {
  content: "\e806";
}

/* '' */
.icon-fm-save:before {
  content: "\e807";
}

/* '' */
.icon-fm-search:before {
  content: "\e808";
}

/* '' */
.icon-fm-sent:before {
  content: "\e809";
}

/* '' */
.icon-fm-settings:before {
  content: "\e80a";
}

/* '' */
.icon-fm-sort:before {
  content: "\e80b";
}

/* '' */
.icon-fm-test-results:before {
  content: "\e80c";
}

/* '' */
.icon-fm-starred:before {
  content: "\e80d";
}

/* '' */
.icon-fm-trash:before {
  content: "\e80e";
}

/* '' */
.icon-fm-starred-chosen:before {
  content: "\e80f";
}

/* '' */
.icon-fm-unlock:before {
  content: "\e810";
}

/* '' */
.icon-fm-3dotssubmenu:before {
  content: "\e811";
}

/* '' */
.icon-fm-vitals:before {
  content: "\e812";
}

/* '' */
.icon-fm-visible:before {
  content: "\e813";
}

/* '' */
.icon-fm-addressbook:before {
  content: "\e814";
}

/* '' */
.icon-fm-allergies:before {
  content: "\e815";
}

/* '' */
.icon-fm-archive:before {
  content: "\e816";
}

/* '' */
.icon-fm-attachments-alerts:before {
  content: "\e817";
}

/* '' */
.icon-fm-body-measurement:before {
  content: "\e818";
}

/* '' */
.icon-fm-billing:before {
  content: "\e819";
}

/* '' */
.icon-fm-calendar:before {
  content: "\e81a";
}

/* '' */
.icon-fm-check:before {
  content: "\e81b";
}

/* '' */
.icon-freddiemed-icon_video-call:before {
  content: "\e81c";
}

/* '' */
.icon-fm-care-group:before {
  content: "\e81d";
}

/* '' */
.icon-fm-contact-card:before {
  content: "\e81e";
}

/* '' */
.icon-fm-currentappointment:before {
  content: "\e81f";
}

/* '' */
.icon-fm-diagnosis-management:before {
  content: "\e820";
}

/* '' */
.icon-fm-diagnostics-request:before {
  content: "\e821";
}

/* '' */
.icon-fm-docs-temps:before {
  content: "\e822";
}

/* '' */
.icon-fm-draft:before {
  content: "\e823";
}

/* '' */
.icon-fm-dropdown:before {
  content: "\e824";
}

/* '' */
.icon-fm-editoslot:before {
  content: "\e825";
}

/* '' */
.icon-fm-exit:before {
  content: "\e826";
}

/* '' */
.icon-fm-female:before {
  content: "\e827";
}

/* '' */
.icon-fm-goback:before {
  content: "\e828";
}

/* '' */
.icon-fm-group:before {
  content: "\e829";
}

/* '' */
.icon-worklist:before {
  content: "\e82a";
}

/* '' */
.icon-icon-worklist-new:before {
  content: "\e82b";
}

/* '' */
.icon-icon-go-to-calendar:before {
  content: "\e82c";
}

/* '' */
.icon-fm-icon-cancelled:before {
  content: "\e82d";
}

/* '' */
.icon-fm-icon-checkedin:before {
  content: "\e82e";
}

/* '' */
.icon-icon-preassessment:before {
  content: "\e82f";
}

/* '' */
.icon-upgrade:before {
  content: "\e830";
}

/* '' */
.icon-two-step:before {
  content: "\e831";
}

/* '' */
.icon-freddiemed-icon_video-call-exit-fulscreen:before {
  content: "\e832";
}

/* '' */
.icon-freddiemed-icon_video-call-fulscreen:before {
  content: "\e833";
}

/* '' */
.icon-freddiemed-icon_video-call-minimize-window:before {
  content: "\e834";
}

/* '' */
.icon-freddiemed-icon_video-call-mute:before {
  content: "\e835";
}

/* '' */
.icon-freddiemed-icon_video-call-regularscreen:before {
  content: "\e836";
}

/* '' */
.icon-freddiemed-icon_video-call-thumbnail:before {
  content: "\e837";
}

/* '' */
.icon-freddiemed-icon_video-call-unmute:before {
  content: "\e838";
}

/* '' */
.icon-freddiemed-icon_video-disabled:before {
  content: "\e839";
}

/* '' */
.icon-fm-checkbox:before {
  content: "\e83a";
}

/* '' */
.icon-fm-related-invoice:before {
  content: "\e83b";
}

/* '' */
.icon-icon-uploaddoc:before {
  content: "\e83c";
}

/* '' */
.icon-fm-pencil:before {
  content: "\e83d";
}

/* '' */
.icon-fm-pause:before {
  content: "\e83e";
}

/* '' */
.icon-pending-approval:before {
  content: "\e83f";
}

/* '' */
.icon-filter_icon:before {
  content: "\e840";
}

/* '' */
.icon-fm-hamburger-menu:before {
  content: "\e858";
}

/* '' */
.icon-fm-heart:before {
  content: "\e859";
}

/* '' */
.icon-fm-immunisations:before {
  content: "\e85a";
}

/* '' */
.icon-fm-important:before {
  content: "\e85b";
}

/* '' */
.icon-fm-inbox:before {
  content: "\e85c";
}

/* '' */
.icon-fm-invisible:before {
  content: "\e85d";
}

/* '' */
.icon-fm-invoice:before {
  content: "\e85e";
}

/* '' */
.icon-fm-label:before {
  content: "\e85f";
}

/* '' */
.icon-fm-link:before {
  content: "\e860";
}

/* '' */
.icon-fm-logout:before {
  content: "\e861";
}

/* '' */
.icon-fm-lock:before {
  content: "\e862";
}

/* '' */
.icon-fm-mais:before {
  content: "\e863";
}

/* '' */
.icon-fm-message-balao:before {
  content: "\e864";
}

/* '' */
.icon-fm-microphone:before {
  content: "\e865";
}

/* '' */
.icon-fm-messages:before {
  content: "\e866";
}

/* '' */
.icon-fm-navigation-collapse:before {
  content: "\e867";
}

/* '' */
.icon-fm-navigation-attachment:before {
  content: "\e868";
}

/* '' */
.icon-fm-navigation-download:before {
  content: "\e869";
}

/* '' */
.icon-fm-navigation-left:before {
  content: "\e86a";
}

/* '' */
.icon-fm-navigation-dropdown:before {
  content: "\e86b";
}

/* '' */
.icon-fm-navigation-right:before {
  content: "\e86c";
}

/* '' */
.icon-fm-notifications:before {
  content: "\e86d";
}

/* '' */
.icon-fm-patientfiles:before {
  content: "\e86e";
}

/* '' */
.icon-fm-patient-health-data:before {
  content: "\e86f";
}

/* '' */
.icon-fm-prescription-history:before {
  content: "\e870";
}

/* '' */
.icon-multidep:before {
  content: "\e871";
}

/* '' */
.icon-fm-icon-booking-calendar:before {
  content: "\e872";
}

/* '' */
.icon-fm-vitals_1:before {
  content: "\e873";
}

/* '' */
.icon-fm-testresults:before {
  content: "\e874";
}

/* '' */
.icon-fm-icon-drag-drop:before {
  content: "\e875";
}

/* '' */
.icon-fm-icon-info:before {
  content: "\e876";
}

/* '' */
.icon-zendesk:before {
  content: "\e877";
}

/* '' */
.icon-fm-icon-noshow:before {
  content: "\e879";
}

/* '' */
.icon-fm-icon-pin:before {
  content: "\e87a";
}

/* '' */
.icon-fm-icon-immunisations:before {
  content: "\e87b";
}

/* '' */
.icon-fm-icon-screenings:before {
  content: "\e87c";
}

/* '' */
.icon-fm-icon-allergiesnew:before {
  content: "\e87d";
}

/* '' */
.icon-fm-icon-duplicate:before {
  content: "\e87e";
}

/* '' */
.icon-fm-icon-demographics:before {
  content: "\e87f";
}

/* '' */
.icon-fm-icon-summary:before {
  content: "\e880";
}

/* '' */
.icon-fm-icon-dashboard:before {
  content: "\e881";
}

/* '' */
.icon-patient-files-v2:before {
  content: "\e882";
}

/* '' */
.icon-addendum-2:before {
  content: "\e883";
}

/* '' */
.icon-addendum-3:before {
  content: "\e884";
}

/* '' */
.icon-alert-immunisations:before {
  content: "\e885";
}

/* '' */
.icon-user-profile:before {
  content: "\e886";
}

/* '' */
.icon-examination-icon:before {
  content: "\e887";
}

/* '' */
.icon-check-in-empty-state:before {
  content: "\e888";
}

/* '' */
.icon-icon-attachments-examination:before {
  content: "\e889";
}

/* '' */
.icon-fm-icon-tick:before {
  content: "\e88a";
}

/* '' */
.icon-fm-icon-pencil:before {
  content: "\e88b";
}

/* '' */
.icon-archive:before {
  content: "\e8b4";
}

/* '' */
.icon-star-filled:before {
  content: "\e8b5";
}

/* '' */
.icon-unarchive:before {
  content: "\e8b6";
}

/* '' */
@font-face {
  font-display: swap;
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fm-icons/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(/assets/fm-icons/MaterialIcons-Regular.woff2) format("woff2"), url(/assets/fm-icons/MaterialIcons-Regular.woff) format("woff"), url(/assets/fm-icons/MaterialIcons-Regular.ttf) format("truetype");
}
/** =================== MONTSERRAT =================== **/
/** Montserrat Regular **/
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/webfonts/Montserrat-Regular.eot");
  src: url("/assets/webfonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/Montserrat-Regular.woff2") format("woff2"), url("/assets/webfonts/Montserrat-Regular.woff") format("woff");
}
/** Montserrat Regular-Italic **/
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url("/assets/webfonts/Montserrat-Italic.eot");
  src: url("/assets/webfonts/Montserrat-Italic.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/Montserrat-Italic.woff2") format("woff2"), url("/assets/webfonts/Montserrat-Italic.woff") format("woff");
}
/** Montserrat Medium **/
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/webfonts/Montserrat-Medium.eot");
  src: url("/assets/webfonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/Montserrat-Medium.woff2") format("woff2"), url("/assets/webfonts/Montserrat-Medium.woff") format("woff");
}
/** Montserrat Medium-Italic **/
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: italic;
  src: url("/assets/webfonts/Montserrat-MediumItalic.eot");
  src: url("/assets/webfonts/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/Montserrat-MediumItalic.woff2") format("woff2"), url("/assets/webfonts/Montserrat-MediumItalic.woff") format("woff");
}
/** Montserrat SemiBold **/
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("/assets/webfonts/Montserrat-SemiBold.eot");
  src: url("/assets/webfonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/Montserrat-SemiBold.woff2") format("woff2"), url("/assets/webfonts/Montserrat-SemiBold.woff") format("woff");
}
/** Montserrat SemiBold-Italic **/
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-weight: 600;
  font-style: italic;
  src: url("/assets/webfonts/Montserrat-SemiBoldItalic.eot");
  src: url("/assets/webfonts/Montserrat-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/Montserrat-SemiBoldItalic.woff2") format("woff2"), url("/assets/webfonts/Montserrat-SemiBoldItalic.woff") format("woff");
}
/** Montserrat Bold **/
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("/assets/webfonts/Montserrat-Bold.eot");
  src: url("/assets/webfonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/Montserrat-Bold.woff2") format("woff2"), url("/assets/webfonts/Montserrat-Bold.woff") format("woff");
}
/** Montserrat Bold-Italic **/
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-weight: 700;
  font-style: italic;
  src: url("/assets/webfonts/Montserrat-BoldItalic.eot");
  src: url("/assets/webfonts/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/Montserrat-BoldItalic.woff2") format("woff2"), url("/assets/webfonts/Montserrat-BoldItalic.woff") format("woff");
}
/** Montserrat ExtraBold **/
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  src: url("/assets/webfonts/Montserrat-ExtraBold.eot");
  src: url("/assets/webfonts/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/Montserrat-ExtraBold.woff2") format("woff2"), url("/assets/webfonts/Montserrat-ExtraBold.woff") format("woff");
}
/** Montserrat ExtraBold-Italic **/
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-weight: 800;
  font-style: italic;
  src: url("/assets/webfonts/Montserrat-ExtraBoldItalic.eot");
  src: url("/assets/webfonts/Montserrat-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("/assets/webfonts/Montserrat-ExtraBoldItalic.woff") format("woff");
}
.error-snack.mat-snack-bar-container,
.info-snack.mat-snack-bar-container {
  background: #f44336;
  color: #ffffff;
  padding: 30px;
  text-align: center;
  white-space: pre-wrap;
}
.error-snack.mat-snack-bar-container span,
.info-snack.mat-snack-bar-container span {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

.error-snack.mat-snack-bar-container .mat-button {
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: hidden;
  padding-right: 8px;
}
.error-snack.mat-snack-bar-container .mat-button:before {
  content: "\e826";
  font-family: "fontello", sans-serif;
  color: #ffffff;
  font-size: 24px;
}

.info-snack.mat-snack-bar-container {
  background: #597bef;
}
.info-snack.mat-snack-bar-container .mat-button span {
  color: #f44336;
}

.doctor-review-card {
  font-family: "Montserrat", sans-serif;
  color: #000000;
}
.doctor-review-card .mat-card-header-text {
  margin: 0;
}
.doctor-review-card .mat-card-title {
  font-weight: 600;
}
.doctor-review-card .mat-card-subtitle {
  color: #000000;
}

/* DIALOG */
.fm-component,
.fm-popup {
  /** Main button */
}
.fm-component #fm-main-action,
.fm-popup #fm-main-action {
  position: fixed;
  bottom: 32px;
  right: 32px;
}

.mat-dialog-container {
  position: relative;
  height: auto !important;
}
.mat-dialog-container:before {
  top: 0;
  right: 0;
}

.fm-dialog-backdrop-blue {
  background-color: rgba(89, 124, 239, 0.24);
}

.dialog-profile mat-dialog-container {
  padding: 0;
  background-color: #f3f3f3;
  overflow-x: hidden;
}
.dialog-profile mat-dialog-container .fm-content {
  padding: 30px;
}

.dialog-booking mat-dialog-container {
  padding: 0;
  background-color: #ffffff;
  overflow-x: hidden;
}
.dialog-booking mat-dialog-container .fm-content {
  padding: 30px;
}

.blue-background .mat-dialog-container {
  background: #597bef;
}

.no-padding .mat-dialog-container {
  padding: 0;
}

.freddie-dialog mat-dialog-container {
  padding: 0 0 20px 0;
  background-color: #ffffff;
}
.freddie-dialog mat-dialog-container .mat-dialog-close-btn {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 40px;
  height: 40px;
  background: none;
  border: 0px;
  color: #ffffff;
  z-index: 1;
}
.freddie-dialog mat-dialog-container .mat-dialog-close-btn:hover {
  color: #f3f3f3;
}
.freddie-dialog mat-dialog-container .mat-dialog-title {
  background-color: #597bef;
  color: #ffffff;
  padding: 18px 60px 18px 35px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.freddie-dialog mat-dialog-container mat-dialog-content,
.freddie-dialog mat-dialog-container mat-dialog-actions {
  padding: 0 36px;
  margin: 0;
}

.switch-user-dialog mat-dialog-container {
  border-radius: 4px;
}
.switch-user-dialog mat-dialog-container #close-btn {
  color: #597bef;
  top: 20px;
}
.switch-user-dialog mat-dialog-container #close-btn mat-icon {
  font-size: 25px;
}

.dialog_patient_files ::ng-deep html.cdk-global-scrollblock {
  overflow: hidden !important;
}
.dialog_patient_files .inner-box {
  padding: 18px 0;
  box-sizing: border-box;
}
.dialog_patient_files mat-card {
  z-index: 2;
}
.dialog_patient_files .form {
  z-index: 2;
}
.dialog_patient_files .form.assessment {
  border-top: 15px solid #f3f3f3;
}
.dialog_patient_files .form.medical-history {
  border-top: 15px solid #f8da44;
}
.dialog_patient_files .form.examination {
  border-top: 15px solid #ffc764;
}
.dialog_patient_files .form.diagnostic-results {
  border-top: 15px solid #f8a744;
}
.dialog_patient_files .form.diagnosis-management {
  border-top: 15px solid #f87e44;
}
.dialog_patient_files .form.summary {
  border-top: 15px solid #f86144;
}
.dialog_patient_files .form.addendum {
  border-top: 15px solid #f3f3f3;
}
.dialog_patient_files .form.report {
  border-top: 15px solid #fb86b9;
}
.dialog_patient_files .form.referral {
  border-top: 15px solid #ffb4d5;
}
.dialog_patient_files .form.diagnostics-request {
  border-top: 15px solid #a6aefd;
}
.dialog_patient_files .form.prescription {
  border-top: 15px solid #9d6ef7;
}
.dialog_patient_files .form.invoice {
  border-top: 15px solid #1d93e3;
}
.dialog_patient_files .form.attachments-alerts {
  border-top: 15px solid #28c8f4;
}
.dialog_patient_files .form.templates {
  border-top: 15px solid #00cd9c;
}
.dialog_patient_files .form.patient-files {
  border-top: 15px solid #6587fb;
}
.dialog_patient_files .form.warn-icon {
  border-top: 15px solid #ffffff;
}
.dialog_patient_files .form.prescription-builder {
  border-top: 15px solid #9d6ef7;
}
.dialog_patient_files .form.billing {
  border-top: 15px solid #b9d925;
}
.dialog_patient_files .form.files {
  border-top: 15px solid #28c8f4;
}
.dialog_patient_files .form.screening {
  border-top: 15px solid #f86144;
}
.dialog_patient_files .form.immunisation {
  border-top: 15px solid #f8da44;
}
.dialog_patient_files .form .sub-title {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #5e5e5e;
}
.dialog_patient_files .buttons {
  z-index: 1;
  margin: 0 20px;
}
.dialog_patient_files .buttons .save {
  color: #ffffff;
  background: #597bef;
}
.dialog_patient_files .buttons .confirm {
  color: #13b7e4;
}
.dialog_patient_files .buttons .wrapper {
  margin-top: -17px;
}
.dialog_patient_files .buttons .wrapper.adjust {
  margin-top: 0;
}
.dialog_patient_files .buttons .wrapper button {
  padding: 23px 30px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: center;
  color: #5e5e5e;
  background-color: #ffffff;
}
.dialog_patient_files .buttons .wrapper button:hover {
  color: #597bef;
}
.dialog_patient_files .buttons .wrapper button:disabled {
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.dialog_patient_files .buttons .wrapper button:disabled:hover {
  color: #5e5e5e;
}
.dialog_patient_files .buttons .wrapper button.new {
  background-color: rgba(19, 183, 208, 0.06);
}

.mat-form-field-should-float .mat-form-field-label {
  color: #597bef;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.4px;
}

.mat-form-field {
  line-height: normal;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #263238;
}
.mat-form-field .mat-form-field-underline {
  bottom: 1.25em;
  height: 2px;
  background-color: rgba(94, 94, 94, 0.2);
  color: #263238;
}

.large-panel .mat-option {
  max-width: 270px;
}
.large-panel.mat-autocomplete-panel {
  min-width: 270px;
}

.mat-option.fm-user-item {
  min-height: 64px;
  line-height: 100% !important;
}

.fm-popup {
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  z-index: 999;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.fm-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(59, 59, 59, 0.12);
  z-index: 998;
}

.fm-centered-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  z-index: 999;
  background: transparent;
}

.fm-centered-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #597bef, #13b7e4);
  z-index: 998;
}

/* FONTS */
/* HEADER */
/* FOOTER */
/* TABLE */
/* MAIN COLORS */
/* USER STATUS */
/* COLORS */
/* SHADOWS */
/* MIXINS */
/* Terms agreement */
/* BORDERS */
/* ICONS BG */
/* Dashboard appointment */
/* PATIENT FILES */
/* TAGS */
/* OPTIONS */
/* TABLE */
/* DATEPICKER */
.mat-datepicker-toggle .mat-icon-button {
  width: auto;
  height: auto;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper:before {
  content: "\e824";
  font-size: 24px;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper .mat-datepicker-toggle-default-icon {
  display: none;
}

/* BUTTONS */
button.mat-fab.btn-add {
  background: #ffffff;
  border-color: #ffffff;
  color: #5e5e5e;
  width: 32px;
  height: 32px;
}
button.mat-fab.btn-add span {
  padding: 0;
  margin-left: -2px;
  line-height: 30px;
}
button.mat-fab.btn-add span i {
  vertical-align: unset;
}
button.mat-fab.btn-add span i:before {
  font-size: 30px;
  margin-left: 1.5px;
}

/* BUTTONS */
button.btn-blue {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #597bef;
  background-color: #597bef;
  color: #fff;
  max-height: 38px;
}

/* INPUT */
.mat-input-element {
  font-family: "Montserrat", sans-serif;
}

/* SELECT */
.mat-select .mat-select-value {
  font-family: "Montserrat", sans-serif;
}
.mat-select .mat-select-arrow {
  width: auto !important;
  height: auto !important;
  border-top: 0 !important;
  border-left: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-right: 0 !important;
  margin-bottom: 3px;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/*profile header*/
.mat-raised-button {
  background-color: #13b7e4;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: lowercase;
  box-shadow: 0px 1px 3px 0px rgba(59, 59, 59, 0.12), 0 1px 2px 0px rgba(59, 59, 59, 0.24);
}

button {
  font-family: "Montserrat", sans-serif;
}

button.mat-button,
button.mat-raised-button {
  border-radius: 20px;
}

.mat-button {
  font-weight: bold;
}
.mat-button i {
  font-size: 32px;
}
.mat-button.primary {
  color: #13b7e4;
  border: 2px solid #13b7e4;
}
.mat-button.transparent {
  background: transparent;
  color: #13b7e4;
}
.mat-button.azure {
  background: #13b7e4;
  color: #ffffff;
}
.mat-button.soft-blue {
  font-weight: 600;
  background: #597bef;
  color: #ffffff;
}
button,
a.button {
  outline: inherit;
}
button.mat-raised-button,
a.button.mat-raised-button {
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(59, 59, 59, 0.12), 0 0 2px 0 rgba(59, 59, 59, 0.24);
  background-color: #13b7e4;
  color: #ffffff;
}
button.mat-raised-button:hover,
a.button.mat-raised-button:hover {
  box-shadow: 0 8px 8px 0 rgba(59, 59, 59, 0.12), 0 0 8px 0 rgba(59, 59, 59, 0.24);
}
button.mat-raised-button[disabled], button.mat-raised-button[disabled]:hover,
a.button.mat-raised-button[disabled],
a.button.mat-raised-button[disabled]:hover {
  box-shadow: 0 2px 2px 0 rgba(59, 59, 59, 0.12), 0 0 2px 0 rgba(59, 59, 59, 0.24);
}
button.mat-fab,
a.button.mat-fab {
  min-width: 0;
  padding: 0;
  border-radius: 50%;
  line-height: 36px;
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  border: solid 2px #597bef;
}
button.mat-button.mat-primary, button.mat-raised-button.mat-primary, button.mat-fab.mat-primary, button.mat-mini-fab.mat-primary,
a.button.mat-button.mat-primary,
a.button.mat-raised-button.mat-primary,
a.button.mat-fab.mat-primary,
a.button.mat-mini-fab.mat-primary {
  background-color: #597bef;
  color: #ffffff;
}
button.mat-button.mat-accent, button.mat-raised-button.mat-accent, button.mat-fab.mat-accent, button.mat-mini-fab.mat-accent,
a.button.mat-button.mat-accent,
a.button.mat-raised-button.mat-accent,
a.button.mat-fab.mat-accent,
a.button.mat-mini-fab.mat-accent {
  border-color: #13b7e4;
  background-color: #13b7e4;
  color: #ffffff;
}
button.mat-button.mat-warn, button.mat-raised-button.mat-warn, button.mat-fab.mat-warn, button.mat-mini-fab.mat-warn,
a.button.mat-button.mat-warn,
a.button.mat-raised-button.mat-warn,
a.button.mat-fab.mat-warn,
a.button.mat-mini-fab.mat-warn {
  border-color: #ef76e8;
  background-color: #ef76e8;
  color: #ffffff;
}
button.mat-button[disabled], button.mat-raised-button[disabled], button.mat-fab[disabled], button.mat-mini-fab[disabled],
a.button.mat-button[disabled],
a.button.mat-raised-button[disabled],
a.button.mat-fab[disabled],
a.button.mat-mini-fab[disabled] {
  border-width: 0;
  background-color: transparent;
}

.active_link {
  position: relative;
  font-weight: 800;
  background-color: #ffffff;
  overflow: hidden;
}
.active_link:before {
  content: "";
  position: absolute;
  top: -1px;
  right: 0px;
  border-style: solid;
  border-color: #13b7e4;
  display: block;
  width: 0;
  border-width: 0px 22px 22px 0;
  /* border-color: transparent $white transparent transparent; */
  border-radius: 0 0 0 2px;
}
.active_link:after {
  content: "";
  position: absolute;
  top: 10px;
  right: -6px;
  width: 32px;
  height: 1px;
  background: rgba(59, 59, 59, 0.12);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-box-shadow: rgba(59, 59, 59, 0.12), -rgba(59, 59, 59, 0.24);
  box-shadow: rgba(59, 59, 59, 0.12), rgba(59, 59, 59, 0.24), 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.active_link span:before {
  content: "";
  position: absolute;
  top: -15px;
  right: -18px;
  width: 36px;
  height: 30px;
  background: #f7f7f7;
  transform: rotate(45deg);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5e5e5e;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #5e5e5e;
}

.inverse-button {
  background: none;
  color: #13b7e4;
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}
.inverse-button:hover {
  cursor: pointer;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #597bef;
}

/* FONTS */
/* HEADER */
/* FOOTER */
/* TABLE */
/* MAIN COLORS */
/* USER STATUS */
/* COLORS */
/* SHADOWS */
/* MIXINS */
/* Terms agreement */
/* BORDERS */
/* ICONS BG */
/* Dashboard appointment */
/* PATIENT FILES */
/* TAGS */
/* OPTIONS */
/* TABLE */
/* DATEPICKER */
.mat-datepicker-toggle .mat-icon-button {
  width: auto;
  height: auto;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper:before {
  content: "\e824";
  font-size: 24px;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper .mat-datepicker-toggle-default-icon {
  display: none;
}

/* BUTTONS */
button.mat-fab.btn-add {
  background: #ffffff;
  border-color: #ffffff;
  color: #5e5e5e;
  width: 32px;
  height: 32px;
}
button.mat-fab.btn-add span {
  padding: 0;
  margin-left: -2px;
  line-height: 30px;
}
button.mat-fab.btn-add span i {
  vertical-align: unset;
}
button.mat-fab.btn-add span i:before {
  font-size: 30px;
  margin-left: 1.5px;
}

/* BUTTONS */
button.btn-blue {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #597bef;
  background-color: #597bef;
  color: #fff;
  max-height: 38px;
}

/* INPUT */
.mat-input-element {
  font-family: "Montserrat", sans-serif;
}

/* SELECT */
.mat-select .mat-select-value {
  font-family: "Montserrat", sans-serif;
}
.mat-select .mat-select-arrow {
  width: auto !important;
  height: auto !important;
  border-top: 0 !important;
  border-left: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-right: 0 !important;
  margin-bottom: 3px;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/*profile header*/
/**
 * Variables declared here can be overridden by consuming applications, with
 * the help of the `!default` flag.
 *
 * @example
 *     // overriding $hoverColor
 *     $hoverColor: rgba(red, 0.05);
 *
 *     // overriding image path
 *     $flagsImagePath: 'images/';
 *
 *     // import the scss file after the overrides
 */
.intl-tel-input {
  position: relative;
  display: inline-block;
}

.iti-flag {
  margin-right: 10px;
}

.intl-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.intl-tel-input .hide {
  display: none;
}

.intl-tel-input .v-hide {
  visibility: hidden;
}

.intl-tel-input input,
.intl-tel-input input[type=text],
.intl-tel-input input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.intl-tel-input .flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.intl-tel-input .selected-flag {
  z-index: 1;
  position: relative;
  width: 36px;
  height: 100%;
  padding: 0 0 0 8px;
}

.intl-tel-input .selected-flag .iti-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.intl-tel-input .selected-flag .iti-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid rgba(94, 94, 94, 0.72);
}

.intl-tel-input .selected-flag .iti-arrow.up {
  border-top: none;
  border-bottom: 4px solid rgba(94, 94, 94, 0.72);
}

.intl-tel-input .country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: rgba(59, 59, 59, 0.24);
  background-color: #ffffff;
  border: 1px solid #5e5e5e;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
}

.intl-tel-input .country-list.dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

.intl-tel-input .country-list .flag-box {
  display: inline-block;
  width: 20px;
}

@media (max-width: 500px) {
  .intl-tel-input .country-list {
    white-space: normal;
  }
}
.intl-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  background-color: transparent;
  margin-top: 0;
  border-bottom: 1px solid #5e5e5e;
}

.intl-tel-input .country-list .country {
  padding: 5px 10px;
}

.intl-tel-input .country-list .country .dial-code {
  color: #999;
}

.intl-tel-input .country-list .country.highlight {
  background-color: rgba(255, 255, 255, 0.6);
}

.intl-tel-input .country-list .flag-box,
.intl-tel-input .country-list .country-name,
.intl-tel-input .country-list .dial-code {
  vertical-align: middle;
}

.intl-tel-input .country-list .flag-box,
.intl-tel-input .country-list .country-name {
  margin-right: 6px;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type=text],
.intl-tel-input.allow-dropdown input[type=tel],
.intl-tel-input.separate-dial-code input,
.intl-tel-input.separate-dial-code input[type=text],
.intl-tel-input.separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.intl-tel-input.allow-dropdown .flag-container,
.intl-tel-input.separate-dial-code .flag-container {
  right: auto;
  left: 0;
}

.intl-tel-input.allow-dropdown .selected-flag,
.intl-tel-input.separate-dial-code .selected-flag {
  width: 46px;
}

.intl-tel-input.allow-dropdown .flag-container:hover {
  cursor: pointer;
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: rgba(255, 255, 255, 0.6);
}

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover,
.intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
  cursor: default;
}

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag,
.intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag {
  background-color: transparent;
}

.intl-tel-input.separate-dial-code .selected-flag {
  background-color: rgba(255, 255, 255, 0.6);
  display: table;
}

.intl-tel-input.separate-dial-code .selected-dial-code {
  display: table-cell;
  vertical-align: middle;
  padding-left: 28px;
}

.intl-tel-input.separate-dial-code.iti-sdc-2 input,
.intl-tel-input.separate-dial-code.iti-sdc-2 input[type=text],
.intl-tel-input.separate-dial-code.iti-sdc-2 input[type=tel] {
  padding-left: 66px;
}

.intl-tel-input.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 60px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=text],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=tel] {
  padding-left: 76px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag {
  width: 70px;
}

.intl-tel-input.separate-dial-code.iti-sdc-3 input,
.intl-tel-input.separate-dial-code.iti-sdc-3 input[type=text],
.intl-tel-input.separate-dial-code.iti-sdc-3 input[type=tel] {
  padding-left: 74px;
}

.intl-tel-input.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 68px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel] {
  padding-left: 84px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
  width: 78px;
}

.intl-tel-input.separate-dial-code.iti-sdc-4 input,
.intl-tel-input.separate-dial-code.iti-sdc-4 input[type=text],
.intl-tel-input.separate-dial-code.iti-sdc-4 input[type=tel] {
  padding-left: 82px;
}

.intl-tel-input.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 76px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=text],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=tel] {
  padding-left: 92px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
  width: 86px;
}

.intl-tel-input.separate-dial-code.iti-sdc-5 input,
.intl-tel-input.separate-dial-code.iti-sdc-5 input[type=text],
.intl-tel-input.separate-dial-code.iti-sdc-5 input[type=tel] {
  padding-left: 90px;
}

.intl-tel-input.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 84px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=text],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=tel] {
  padding-left: 100px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 .selected-flag {
  width: 94px;
}

.intl-tel-input.iti-container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.intl-tel-input.iti-container:hover {
  cursor: pointer;
}

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .intl-tel-input .country-list .country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti-flag {
  width: 20px;
}

.iti-flag.be {
  width: 18px;
}

.iti-flag.ch {
  width: 15px;
}

.iti-flag.mc {
  width: 19px;
}

.iti-flag.ne {
  width: 18px;
}

.iti-flag.np {
  width: 13px;
}

.iti-flag.va {
  width: 15px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-size: 5630px 15px;
  }
}
.iti-flag.ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti-flag.ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti-flag.ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti-flag.af {
  height: 14px;
  background-position: -66px 0px;
}

.iti-flag.ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti-flag.ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti-flag.al {
  height: 15px;
  background-position: -132px 0px;
}

.iti-flag.am {
  height: 10px;
  background-position: -154px 0px;
}

.iti-flag.ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti-flag.aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti-flag.ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti-flag.as {
  height: 10px;
  background-position: -242px 0px;
}

.iti-flag.at {
  height: 14px;
  background-position: -264px 0px;
}

.iti-flag.au {
  height: 10px;
  background-position: -286px 0px;
}

.iti-flag.aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti-flag.ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti-flag.az {
  height: 10px;
  background-position: -352px 0px;
}

.iti-flag.ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti-flag.bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti-flag.bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti-flag.be {
  height: 15px;
  background-position: -440px 0px;
}

.iti-flag.bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti-flag.bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti-flag.bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti-flag.bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti-flag.bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti-flag.bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti-flag.bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti-flag.bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti-flag.bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti-flag.bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti-flag.br {
  height: 14px;
  background-position: -680px 0px;
}

.iti-flag.bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti-flag.bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti-flag.bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti-flag.bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti-flag.by {
  height: 10px;
  background-position: -790px 0px;
}

.iti-flag.bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti-flag.ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti-flag.cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti-flag.cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti-flag.cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti-flag.cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti-flag.ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti-flag.ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti-flag.ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti-flag.cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti-flag.cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti-flag.cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti-flag.co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti-flag.cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti-flag.cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti-flag.cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti-flag.cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti-flag.cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti-flag.cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti-flag.cy {
  height: 13px;
  background-position: -1225px 0px;
}

.iti-flag.cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti-flag.de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti-flag.dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti-flag.dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti-flag.dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti-flag.dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti-flag.do {
  height: 13px;
  background-position: -1379px 0px;
}

.iti-flag.dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti-flag.ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti-flag.ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti-flag.ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti-flag.eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti-flag.eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti-flag.er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti-flag.es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti-flag.et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti-flag.eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti-flag.fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti-flag.fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti-flag.fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti-flag.fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti-flag.fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti-flag.fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti-flag.ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti-flag.gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti-flag.gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti-flag.ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti-flag.gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti-flag.gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti-flag.gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti-flag.gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti-flag.gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti-flag.gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti-flag.gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti-flag.gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti-flag.gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti-flag.gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti-flag.gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti-flag.gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti-flag.gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti-flag.gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti-flag.gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti-flag.hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti-flag.hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti-flag.hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti-flag.hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti-flag.ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti-flag.hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti-flag.ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti-flag.id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti-flag.ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti-flag.il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti-flag.im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti-flag.in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti-flag.io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti-flag.iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti-flag.ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti-flag.is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti-flag.it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti-flag.je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti-flag.jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti-flag.jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti-flag.jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti-flag.ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti-flag.kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti-flag.kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti-flag.ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti-flag.km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti-flag.kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti-flag.kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti-flag.kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti-flag.kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti-flag.ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti-flag.kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti-flag.la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti-flag.lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti-flag.lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti-flag.li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti-flag.lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti-flag.lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti-flag.ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti-flag.lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti-flag.lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti-flag.lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti-flag.ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti-flag.ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti-flag.mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti-flag.md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti-flag.me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti-flag.mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti-flag.mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti-flag.mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti-flag.mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti-flag.ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti-flag.mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti-flag.mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti-flag.mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti-flag.mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti-flag.mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti-flag.mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti-flag.ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti-flag.mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti-flag.mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti-flag.mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti-flag.mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti-flag.mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti-flag.my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti-flag.mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti-flag.na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti-flag.nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti-flag.ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti-flag.nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti-flag.ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti-flag.ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti-flag.nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti-flag.no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti-flag.np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti-flag.nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti-flag.nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti-flag.nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti-flag.om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti-flag.pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti-flag.pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti-flag.pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti-flag.pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti-flag.ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti-flag.pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti-flag.pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti-flag.pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti-flag.pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti-flag.pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti-flag.ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti-flag.pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti-flag.pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti-flag.py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti-flag.qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti-flag.re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti-flag.ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti-flag.rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti-flag.ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti-flag.rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti-flag.sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti-flag.sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti-flag.sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti-flag.sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti-flag.se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti-flag.sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti-flag.sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti-flag.si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti-flag.sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti-flag.sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti-flag.sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti-flag.sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti-flag.sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti-flag.so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti-flag.sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti-flag.ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti-flag.st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti-flag.sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti-flag.sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti-flag.sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti-flag.sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti-flag.ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti-flag.tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti-flag.td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti-flag.tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti-flag.tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti-flag.th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti-flag.tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti-flag.tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti-flag.tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti-flag.tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti-flag.tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti-flag.to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti-flag.tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti-flag.tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti-flag.tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti-flag.tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti-flag.tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti-flag.ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti-flag.ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti-flag.um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti-flag.us {
  height: 11px;
  background-position: -5241px 0px;
}

.iti-flag.uy {
  height: 14px;
  background-position: -5263px 0px;
}

.iti-flag.uz {
  height: 10px;
  background-position: -5285px 0px;
}

.iti-flag.va {
  height: 15px;
  background-position: -5307px 0px;
}

.iti-flag.vc {
  height: 14px;
  background-position: -5324px 0px;
}

.iti-flag.ve {
  height: 14px;
  background-position: -5346px 0px;
}

.iti-flag.vg {
  height: 10px;
  background-position: -5368px 0px;
}

.iti-flag.vi {
  height: 14px;
  background-position: -5390px 0px;
}

.iti-flag.vn {
  height: 14px;
  background-position: -5412px 0px;
}

.iti-flag.vu {
  height: 12px;
  background-position: -5434px 0px;
}

.iti-flag.wf {
  height: 14px;
  background-position: -5456px 0px;
}

.iti-flag.ws {
  height: 10px;
  background-position: -5478px 0px;
}

.iti-flag.xk {
  height: 15px;
  background-position: -5500px 0px;
}

.iti-flag.ye {
  height: 14px;
  background-position: -5522px 0px;
}

.iti-flag.yt {
  height: 14px;
  background-position: -5544px 0px;
}

.iti-flag.za {
  height: 14px;
  background-position: -5566px 0px;
}

.iti-flag.zm {
  height: 14px;
  background-position: -5588px 0px;
}

.iti-flag.zw {
  height: 10px;
  background-position: -5610px 0px;
}

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("/assets/img/flags.png");
  background-repeat: no-repeat;
  background-color: rgba(94, 94, 94, 0.2);
  background-position: 20px 0;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("/assets/img/flags@2x.png");
  }
}
.iti-flag.np {
  background-color: transparent;
}

/* FONTS */
/* HEADER */
/* FOOTER */
/* TABLE */
/* MAIN COLORS */
/* USER STATUS */
/* COLORS */
/* SHADOWS */
/* MIXINS */
/* Terms agreement */
/* BORDERS */
/* ICONS BG */
/* Dashboard appointment */
/* PATIENT FILES */
/* TAGS */
/* OPTIONS */
/* TABLE */
/* DATEPICKER */
.mat-datepicker-toggle .mat-icon-button {
  width: auto;
  height: auto;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper:before {
  content: "\e824";
  font-size: 24px;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper .mat-datepicker-toggle-default-icon {
  display: none;
}

/* BUTTONS */
button.mat-fab.btn-add {
  background: #ffffff;
  border-color: #ffffff;
  color: #5e5e5e;
  width: 32px;
  height: 32px;
}
button.mat-fab.btn-add span {
  padding: 0;
  margin-left: -2px;
  line-height: 30px;
}
button.mat-fab.btn-add span i {
  vertical-align: unset;
}
button.mat-fab.btn-add span i:before {
  font-size: 30px;
  margin-left: 1.5px;
}

/* BUTTONS */
button.btn-blue {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #597bef;
  background-color: #597bef;
  color: #fff;
  max-height: 38px;
}

/* INPUT */
.mat-input-element {
  font-family: "Montserrat", sans-serif;
}

/* SELECT */
.mat-select .mat-select-value {
  font-family: "Montserrat", sans-serif;
}
.mat-select .mat-select-arrow {
  width: auto !important;
  height: auto !important;
  border-top: 0 !important;
  border-left: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
  border-right: 0 !important;
  margin-bottom: 3px;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/*profile header*/
#close-btn {
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: 10px;
  width: 40px;
  height: 40px;
  background: none;
  border: 0px;
  z-index: 1;
  color: #597bef;
}