.error-snack.mat-snack-bar-container,
.info-snack.mat-snack-bar-container {
    background: $error;
    color: $white;
    padding: 30px;
    text-align: center;
    white-space: pre-wrap;

    span {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-align: center;
        color: $white;
    }
}

.error-snack.mat-snack-bar-container {
    .mat-button {
        position: absolute;
        top: 0px;
        right: 0px;
        overflow: hidden;
        padding-right: 8px;

        &:before {
            content: "\e826"; // * Everitime the icons library change this value has to be changed manually
            font-family: "fontello", sans-serif;
            color: $white;
            font-size: 24px;
        }
    }
}

.info-snack.mat-snack-bar-container {
    background: $primary;

    .mat-button {
        span {
            color: $error;
        }
    }
}
